import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Menu, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import "./PatientSidebarArea.css";

import {
  HomeOutlined,
  UnorderedListOutlined,
  UserOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;

function SideBar() {
  const [name, setUsername] = useState("");
  const navigate = useNavigate();

  // Use useEffect to fetch username from sessionStorage when the component mounts
  useEffect(() => {
    const storedUsername = sessionStorage.getItem("name");
    if (storedUsername) {
      setUsername(storedUsername);
      console.log("Patient name", storedUsername);
    }
  }, []);

  return (
    <Layout>
      <Sider
        // className="sidebar-2 fs-5 ant-layout ant-layout-has-sider"
        breakpoint="md"
        collapsedWidth="0"
        style={{ zIndex: "999999" }}
      >
        <Menu
          theme="dark"
          onClick={({ key }) => {
            navigate(key); // Navigate to the selected menu item
          }}
          style={{ zIndex: "999999" }}
        >
          <Menu.Item key="/ProviderProfileArea" icon={<UserOutlined />}>
            Profile
          </Menu.Item>
          <Menu.Item key="/ChangePasswordArea" icon={<UnorderedListOutlined />}>
            Book Appoinment
          </Menu.Item>
          <Menu.Item key="/bookProcedure" icon={<UnorderedListOutlined />}>
            Book Procedure
          </Menu.Item>
          <Menu.Item key="/ProviderLandingPage" icon={<HistoryOutlined />}>
            History
          </Menu.Item>
        </Menu>
      </Sider>
    </Layout>
  );
}

export default SideBar;
