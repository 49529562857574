import React from "react";
import Footer from "../../components/Shared/Footer";
import ProviderLandingPageArea from "./ProviderLandingPageArea/ProviderLandingPageArea";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import ManageProcedure from "./ProviderLandingPageArea/ManageProcedure";

const ProviderLandingPage = () => {
  return (
    <>
      {/* Render the navigation bar at the top of the page */}
      <HomeThreeNavBar />
      <ProviderLandingPageArea /> {/* Render the provider landing area */}
      <Footer /> {/* Render the footer at the bottom of the page */}
    </>
  );
};

export default ProviderLandingPage;
