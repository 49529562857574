import React, { useState, useEffect } from "react";
import "./AdminProfileArea.css";
import AdminSidebar from "../AdminSidebar";

function AdminProfileArea() {
  return (
    <div className="admin-profile-container">
      <div className="admin-sidebar-area">
        <AdminSidebar />
      </div>
      <div className="admin-main-container">
        <h4>Welcome</h4>
      </div>
    </div>
  );
}

export default AdminProfileArea;
