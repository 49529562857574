import React from "react";

import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import HomeThreeHeroSection from "../HomeThree/HomeThreeHeroSection/HomeThreeHeroSection";
import Footer from "../../components/Shared/Footer";
import HomeThreeAboutArea from "../HomeThree/HomeThreeAboutArea/HomeThreeAboutArea";
import PatientProfileArea from "./PatientProfileArea/PatientProfileArea";

function PatientProfile() {
  return (
    <div>
      <HomeThreeNavBar /> {/* Render the navigation bar */}
      <PatientProfileArea /> {/* Render the patient profile area */}
      <HomeThreeAboutArea /> {/* Render the about area */}
      <Footer /> {/* Render the footer */}
    </div>
  );
}

export default PatientProfile;
