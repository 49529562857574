import React from "react";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import Footer from "../../components/Shared/Footer";
import AboutUsArea from "./AboutUsArea/AboutUsArea";

function AboutUs() {
  return (
    <div>
      <HomeThreeNavBar />
      <AboutUsArea />
      <Footer />
    </div>
  );
}

export default AboutUs;
