import React, { useState, useEffect } from "react";
import "./AdminDashboardArea.css";
import AdminSidebar from "../../AdminProfile/AdminSidebar.js";

function AdminDashboardArea() {
  return (
    <div className="admin-dashboard-container">
      <div className="admin-sidebar-area">
        <AdminSidebar />
      </div>
      <div className="dashboard-main-container">
        <h4>Admin Dashboard</h4>
      </div>
    </div>
  );
}

export default AdminDashboardArea;
