import React from "react";
import AdminProfileArea from "./AdminProfileArea/AdminProfileArea";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import Footer from "../../components/Shared/Footer";
import AdminSideBar from "./AdminSidebar";

function AdminProfile() {
  return (
    <>
      <HomeThreeNavBar />

      <AdminProfileArea />
      <Footer />
    </>
  );
}

export default AdminProfile;
