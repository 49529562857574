import React from "react";
import Footer from "../../../components/Shared/Footer";
import ShopDetailsBanner from "./ShopDetailsBanner/ShopDetailsBanner";
import ShopDetailsDesc from "./ShopDetailsDesc/ShopDetailsDesc";
import { useLocation } from "react-router-dom";
import HomeThreeNavBar from "../../HomeThree/HomeThreeNavBar/HomeThreeNavBar";

const ShopDetails = () => {
  const { state } = useLocation();

  const data = state?.procedureData || {
    id: state.id,
    image: state.image,
    speciality: state.speciality,
    pname: state.pname,
    price: state.price,
    discount: state.discount,
    description: state.description,
    provider_name: state.provider_name,
    provider_id: state.provider_id,
    address: state.address,
    city: state.city,
    country: state.country,
    phone: state.phone,
    doctor_name: state.doctor_name,
    doctor_id: state.doctor_id,
  };

  console.log("Procedure data after login:", state);
  console.log("Data:", data);

  return (
    <>
      <HomeThreeNavBar />
      <ShopDetailsBanner data={data} />
      <ShopDetailsDesc
        procedureId={data.id}
        pname={data.pname}
        description={data.description}
        provider_name={data.provider_name}
        provider_id={data.provider_id}
        address={data.address}
        city={data.city}
        country={data.country}
        phone={data.phone}
        doctor_name={data.doctor_name}
        doctor_id={data.doctor_id}
      />
      <Footer />
    </>
  );
};

export default ShopDetails;
