import React from "react";
import Footer from "../../components/Shared/Footer";
import LoginArea from "./LoginArea/LoginArea";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import { useLocation } from "react-router-dom";

const Login = () => {
  const location = useLocation(); // Use useLocation hook to get the current location object
  console.log("comming data on login page", location);
  const data = location.state?.data; // Extract 'data' from the state property of the location object
  console.log("value of data-->>", data);
  return (
    <>
      {/* Render the navigation bar */}
      <HomeThreeNavBar />
      {/* Conditionally render the LoginArea component with or without data */}
      {data !== null ? <LoginArea data={data} /> : <LoginArea />}
      {/* Render the footer */}
      <Footer />
    </>
  );
};

export default Login;
