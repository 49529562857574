import React from "react";
import { Link } from "react-router-dom";
import HomeSingleFooterBlog from "../HomeSingleFooterBlog/HomeSingleFooterBlog";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-top primary-bg footer-map pos-rel pt-3 pb-3">
          <div className="container">
            <div
              className="row align-items-center"
              style={{ position: "relative", zIndex: "9999" }}
            >
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="footer-contact-info">
                  <Link to="/">
                    <img src="img/logo/treatsmart-logo.jpg" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="footer-menu text-light">
                  <ul>
                    <li>
                      <i className="far fa-envelope m-1"></i> info@treatsmart.in
                    </li>
                    <li>
                      <i className="far fa-link m-1"></i>treatsmart.in
                    </li>
                    <li>
                      <i className="fas fa-map-marker-alt m-1"></i>
                      Pune, India.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="footer-menu text-light">
                  <ul>
                    <li>
                      <Link to="/privacyPolicy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/disclaimerPolicy">Disclaimer Policy</Link>
                    </li>
                    <li>
                      <Link to="/termsAndConditions">Terms and Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-6">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to="/refundPolicy">Claims and Refund Policy</Link>
                    </li>

                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>

                    <li>
                      <Link to="">Book an Appointment</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
