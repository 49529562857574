import React from "react";
import CountUp from "react-countup";

const SingleCount = ({ icon, counter, title }) => {
  return (
    <>
      <div className="col-xl-4 col-lg-4 col-md-6">
        <div className="single-couter counter-box counter-box-white text-center p-3">
          <img src={`img/counter/counter-icon-${icon}.png`} alt="" />
          <h3>
            <span className="theme-color counter">
              <CountUp end={counter} duration={8} />
            </span>
            +
          </h3>
          <h5 className="green-color pb-20">{title}</h5>
        </div>
      </div>
    </>
  );
};

export default SingleCount;
