import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Menu, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import "../PatientLandingPage/PatientLandingPageArea/PatientSidebarArea.css";
import "./AdminSidebar.css";

import {
  HomeOutlined,
  UnorderedListOutlined,
  UserOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;

const AdminSidebar = () => {
  const [name, setUsername] = useState("");
  const navigate = useNavigate(); // Initialize navigate hook

  // Use useEffect to fetch username from sessionStorage when the component mounts
  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      console.log("Patient name", storedUsername);
    }
  }, []);

  return (
    <>
      <Layout style={{ minHeight: "100vh", width: "0", zIndex: "999999" }}>
        <Sider
          // className="fs-5 ant-layout ant-layout-has-sider"
          breakpoint="md"
          collapsedWidth="0"
        >
          <Menu
            theme="dark"
            onClick={({ key }) => {
              navigate(key); // Navigate to the selected menu item
            }}
            className="sidebar-2"
          >
            <Menu.Item key="/adminProfile" icon={<UserOutlined />}>
              Profile
            </Menu.Item>
            <Menu.Item key="/adminDashboard" icon={<HistoryOutlined />}>
              Dashboard
            </Menu.Item>
            <Menu.Item key="/dailyTransaction" icon={<UnorderedListOutlined />}>
              Daily Transaction
            </Menu.Item>
            <Menu.Item
              key="/monthlyTransaction"
              icon={<UnorderedListOutlined />}
            >
              Monthly Transaction
            </Menu.Item>
          </Menu>
        </Sider>
      </Layout>
    </>
  );
};

export default AdminSidebar;
