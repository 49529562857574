import React, { useState, useEffect } from "react";
import PatientArea from "../../PatientLandingPage/PatientLandingPageArea/PatientLandingPageArea";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "./BookProcedureArea.css";

function BookProcedureArea() {
  const [speciality, setSpeciality] = useState([]);
  const [selectedSpeciality, setSelectedSpeciality] = useState();
  const [procedure, setProcedure] = useState([]);
  const [selectedHospital1, setSelectedHospital1] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState();
  const [hospitalNames, setHospitalNames] = useState([]);
  const [selectedProcedure, setSelectedProcedure] = useState();

  const navigate = useNavigate();

  const uniqueHospitalNames = [
    ...new Set(selectedHospital1.map((loc) => loc.name)),
  ];

  const patient_id = localStorage.getItem("patient_id");
  console.log("========>>>", patient_id);

  const handleSpecialityChange = (e) => {
    setSelectedSpeciality(e.target.value);
    setSelectedSpeciality(
      e.target.value === "Choose A Specialty" ? "" : e.target.value
    );
  };

  //   const handleProcedureChange = (e) => {
  //     setSelectedProcedure(e.target.value);
  //     setSelectedProcedure(
  //       e.target.value === "Choose A Procedure" ? "" : e.target.value
  //     );
  //   };

  //1) useeffect code for spocialty - user selects specilty
  useEffect(() => {
    const getspeciality = async () => {
      await fetch("https://api.treatsmart.in/Procedure/speciality", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((result) => {
          console.log("==>", result);
          setSpeciality(result.SpeialityName);
        })
        .catch((error) => {
          console.log("error found" + error);
        });
    };
    getspeciality();
  }, []);

  console.log("all specialities--->>>", speciality);
  //2) useeffect code for procedure - user selects procedure
  useEffect(() => {
    if (selectedSpeciality) {
      const getprocedure = async () => {
        await fetch(
          `https://api.treatsmart.in/Procedure/procedure/${selectedSpeciality}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            console.log("procedure1==>", result);
            setProcedure(result.proceduresName);
          })
          .catch((error) => {
            console.log("error found" + error);
          });
      };
      getprocedure();
    }
  }, [selectedSpeciality]);

  //3) useeffect code to fetch all hospitals based on speciality
  useEffect(() => {
    if (selectedSpeciality) {
      const getlocation = async () => {
        await fetch(
          `https://api.treatsmart.in/Speciality/hospitals/${selectedSpeciality}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())
          .then((result) => {
            console.log("city==>", result);
            setSelectedHospital1(result.cityName);
          })
          .catch((error) => {
            console.log("error found" + error);
          });
      };

      getlocation();
    }
    // else{
    //   getAllHospitals();
    // }
  }, [selectedSpeciality]);

  // to feetch hospitals based on selected procedure

  // to display all the hospitals

  const getAllHospitals = async () => {
    await fetch(`https://api.treatsmart.in/Procedure/all/hospital/names`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("hospitals==>", result);
        setHospitalNames(result.pName);
      })
      .catch((error) => {
        console.log("error found" + error);
      });
  };

  useEffect(() => {
    getAllHospitals();
  }, []);

  // 4) submit handler - Book procedure code
  const onBookProcedure = async (e) => {
    e.preventDefault();

    const searchOption = {
      procedure: selectedProcedure,
      speciality: selectedSpeciality,
      selectedHospital: selectedHospital,
    };

    await fetch(
      "https://api.treatsmart.in/Procedure/speciality/searchProcedurehome",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(searchOption),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          if (result.procedure.length === 0) {
            // No procedures found, display a message to the user
            Swal.fire({
              text: "No procedures found based on the provided criteria.",
              confirmButtonColor: "#8fb569",
              confirmButtonText: "OK",
            });
          } else {
            console.log("result-->>", result);
            // Procedures found, navigate to the shop page with the results
            navigate("/shop", { state: { result } });
          }
        } else {
          // Handle cases where the API request was not successful
          console.log("API request was not successful:", result.error);
          // Display a generic error message to the user
          Swal.fire({
            text: "No Procedure or Hospital found !",
            confirmButtonColor: "#8fb569",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        // Handle network errors or other unexpected errors
        console.log("Error:", error);
        // Display a generic error message to the user
        Swal.fire({
          text: "An error occurred while searching for procedures. Please try again later.",
          confirmButtonColor: "#8fb569",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <div className="book-procedure-container">
      {/* Sidebar for patient area */}
      <div class="patient-sidebar-container">
        <PatientArea />
      </div>
      {/* main content */}
      <div className="procedure-details-container">
        <div className="basic-login">
          <div style={{ textAlign: "center" }}>
            <h5>Book Procedure</h5>
          </div>
          <div>
            <form style={{ marginBottom: "20px" }}>
              <div className="mb-2">
                <label htmlFor="procedure" className="mb-2">
                  <h6 className="theme-color">
                    Speciality<span>*</span>
                  </h6>
                </label>
                <select
                  required
                  id="speciality"
                  className="form-select select_style"
                  value={selectedSpeciality}
                  onChange={(e) => setSelectedSpeciality(e.target.value)}
                >
                  <option value={""}>Select Speciality</option>

                  {speciality.map((specialityName, index) => (
                    <option value={specialityName.speciality} key={index}>
                      {specialityName.speciality}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-2">
                <label htmlFor="procedure" className="mb-2">
                  <h6 className="theme-color">
                    Procedure<span>*</span>
                  </h6>
                </label>
                <select
                  required
                  id="procedure"
                  className="form-select select_style"
                  value={selectedProcedure}
                  onChange={(e) => {
                    setSelectedProcedure(e.target.value);
                  }}
                >
                  <option value="">Select Procedure</option>
                  {selectedSpeciality
                    ? procedure.map((pNames, index) => (
                        <option key={index} value={pNames.pname}>
                          {pNames.pname}
                        </option>
                      ))
                    : ""}
                </select>
              </div>

              <div className="mb-2">
                <label htmlFor="provider" className="mb-2">
                  <h6 className="theme-color">
                    Hospital Name<span></span>
                  </h6>
                </label>
                <select
                  required
                  id="provider"
                  className="form-select select_style"
                  value={selectedHospital}
                  onChange={(e) => setSelectedHospital(e.target.value)}
                >
                  <option value={""}>Select Hospital</option>

                  {selectedSpeciality
                    ? uniqueHospitalNames.map((name, index) => (
                        <option key={index}>{name}</option>
                      ))
                    : hospitalNames.map((hnames, index) => (
                        <option key={index} value={selectedHospital}>
                          {" "}
                          {hnames.name}
                        </option>
                      ))}
                </select>
              </div>

              <div className="col-md-12 text-center mt-3 form-group">
                <button
                  type="submit"
                  className="primary_btn btn-icon-green w-50"
                  onClick={onBookProcedure}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookProcedureArea;
