import React from "react";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import Footer from "../../components/Shared/Footer";
import DailyTransactionArea from "./DailyTransactionArea/DailyTransactionArea";

function DailyTransaction() {
  return (
    <>
      <HomeThreeNavBar />
      <DailyTransactionArea />
      <Footer />
    </>
  );
}

export default DailyTransaction;
