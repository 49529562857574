import React from "react";
import Footer from "../../components/Shared/Footer";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import ProviderGallery from "./ProviderGallery";

function ProviderGallaeryPage() {
  return (
    <div>
      <>
        {/* Render the navigation bar at the top of the page */}
        <HomeThreeNavBar />
        <ProviderGallery /> {/* Render the provider gallery */}
        <Footer /> {/* Render the footer at the bottom of the page */}
      </>
    </div>
  );
}

export default ProviderGallaeryPage;
