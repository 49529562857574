import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Menu, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import "./PatientSidebarArea.css";

import {
  HomeOutlined,
  UnorderedListOutlined,
  UserOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
const { Sider } = Layout;

const PatientArea = () => {
  const [name, setUsername] = useState("");
  const navigate = useNavigate(); // Initialize navigate hook

  // Use useEffect to fetch username from sessionStorage when the component mounts
  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      console.log("Patient name", storedUsername);
    }
  }, []);

  return (
    <>
      <Layout style={{ minHeight: "100vh", width: "0", zIndex: "999999" }}>
        <Sider
          // className="fs-5 ant-layout ant-layout-has-sider"
          breakpoint="md"
          collapsedWidth="0"
        >
          <Menu
            theme="dark"
            onClick={({ key }) => {
              navigate(key); // Navigate to the selected menu item
            }}
            className="sidebar-2"
          >
            <Menu.Item key="/patientprofile" icon={<UserOutlined />}>
              Profile
            </Menu.Item>
            <Menu.Item key="/BookAppointment" icon={<UnorderedListOutlined />}>
              Book Appoinment
            </Menu.Item>
            <Menu.Item key="/bookProcedure" icon={<UnorderedListOutlined />}>
              Book Procedure
            </Menu.Item>
            <Menu.Item key="/bookhistory" icon={<HistoryOutlined />}>
              History
            </Menu.Item>
          </Menu>
        </Sider>
      </Layout>
    </>
  );
};

export default PatientArea;
