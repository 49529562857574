import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Card } from "react-bootstrap";
import "./ProceduresListView.css";

const SingleSecondShop = ({
  id,
  image,
  speciality,
  pname,
  price,
  discount,
  description,
  provider_name,
  address,
  city,
  country,
  phone,
  doctor_name,
  doctor_id,
}) => {
  const Navigate = useNavigate();

  const { state } = useLocation();
  const patient_id = localStorage.getItem("patient_id");
  console.log("========>>>", patient_id);
  const { result } = state;

  const handleRedirect = () => {
    Navigate("/login", {
      state: {
        data: {
          result: true,
          id,
          image,
          speciality,
          pname,
          price,
          discount,
          description,
        },
      },
    });
  };

  const onMoreDetails = () => {
    Navigate("/shopDetails", {
      state: {
        id,
        image,
        speciality,
        pname,
        price,
        discount,
        description,
        provider_name,
        address,
        city,
        country,
        phone,
        doctor_name,
        doctor_id,
      },
    });
  };

  const onBookProcedure = () => {
    // Make an API request to save the data to the backend
    axios
      .post("https://api.treatsmart.in/api/patients/booking", {
        image,
        speciality,
        pname,
        price,
        discount,
        provider_name,
        patient_id,
        procedure_id: id,
      })
      .then((response) => {
        // Handle the successful response
        console.log("Booking successful:", response.data);
        Navigate("/patientlandingpage");
        // Optionally, you can navigate to a success page or display a success message
      })
      .catch((error) => {
        // Handle the error
        console.error("Error booking procedure:", error);
        // Optionally, you can display an error message to the user
      });
  };

  return (
    <>
      <div className="procedure-list-container">
        <div className="procedure-img">
          <img
            src={image}
            alt=""
            style={{
              height: "11rem",
              width: "15rem",
              objectFit: "contain",
            }}
          />
        </div>

        <div className="procedure-details">
          <h5>
            <span className="pro-cat">
              <a>{speciality}</a>
            </span>
          </h5>
          <h4 className="pro-title">{pname}</h4>
          <h5>
            <div className="price">
              <span>₹ {price}</span>
              <span className="old-price">₹ {discount}</span>
            </div>
          </h5>
          <h6 className="pro-title">{provider_name}</h6>
          <h6 className="pro-title">{doctor_name}</h6>
        </div>

        <div className="procedure-action-buttons">
          <div className="">
            {patient_id !== null && (
              <li>
                <button
                  className="primary_btn btn-icon ml-0"
                  onClick={onMoreDetails}
                >
                  <i class="fa fa-medkit"></i>Book Procedure
                </button>
              </li>
            )}
            {patient_id === null && (
              <li>
                <button
                  className="primary_btn btn-icon ml-0"
                  onClick={handleRedirect}
                >
                  <i class="fa fa-medkit"></i> Book Procedure
                </button>
              </li>
            )}

            <button
              className="action-btn"
              onClick={onMoreDetails}
              style={{ backgroundColor: "#e7e7e7", color: "black" }}
            >
              <i className="fas fa-expand text-dark"></i> More Details
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSecondShop;
