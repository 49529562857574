import React from 'react'
import HomeThreeNavBar from '../HomeThree/HomeThreeNavBar/HomeThreeNavBar'
import Footer from '../../components/Shared/Footer'

function refund() {
  return (

        <div> 
        < HomeThreeNavBar />

    <div className='main-div' style={{margin: '20px 20px'}}>
        
        <div>
        <h2>Refund and Cancellation Policy</h2>
        <p>We thank you and appreciate your purchase with us. Please read the
policy, conditions and process carefully as they will give you important
information and guidelines about your rights and obligations as our
customer, concerning any purchase of Services you make through our
Platform unless specifically stated otherwise on our service sale pages or
readers offer advertisements. The policy with regard to processing of
refund shall be in accordance with the clauses as set forth:</p>
<div>
<h4>
1. PROCESSING OF REFUND
</h4>
<p>If a User cancels their consultation any time prior to the scheduled
date, they shall be eligible to receive a refund of the payment made
by them towards the consultation. They shall be eligible for this
refund only if the cancellation is made two days before the scheduled
date of consultation.
If a User cancels their treatment procedure booked on the platform
one week prior to the scheduled date of the procedure, they shall be
eligible to receive a refund of the payment made by them towards the
procedure booking. They shall be eligible for this refund only if the
cancellation is made one week before the scheduled date of
consultation on the platform.
The User shall not be eligible for any refund if they do not attend a
scheduled consultation appointment or avail treatment/procedure
services under a package on the scheduled date and have not
cancelled the booking prior to the scheduled date as per the terms
mentioned above.
The Refund will be initiated to the same account/card used by the
User to make the payment towards consultation and will take 5 (five)
to 15 (fifteen) working days for the claim to be settled.</p>
</div>
<div>
    <h4>2. CANCELLATION BY THE COMPANY</h4>
    <p>The Company will initiate any refunds due pursuant to the present
Refund and Cancellation Policy. The Company at its sole discretion
may cancel any order(s):
a. if it suspects a User has undertaken a fraudulent transaction, or
b. if it suspects a User has undertaken a transaction which is not
in accordance with the Terms of Use, or
c. in case of unavailability of a service, or

d. for any reason outside the control of the Company including
causes related to inefficiency of the hospitals or doctors in
delivering the Services.
e. if the Company does not want to do business with the User.
f. If the amount paid by the User is incorrect regardless of
whether because of an error in a price posted on the platform
or otherwise communicated to the User. This will apply
regardless of whether the issue arises as a result of human
error or a transactional malfunction of the platform.
Further, while all measures are taken to ensure accuracy of service
specifications and pricing, the details of a service as reflected on the
Platform may be inaccurate due to technical issues, typographical
errors or incorrect service information provided to the Company by
the doctors, hospitals, clinics or wellness centres and in such an
event you shall be notified as soon as such error comes to the notice
of the Company. In such event, the Company reserves the right to
cancel your order and refund any money that may have been paid by
you towards availing of such services.
We maintain a negative list of all fraudulent transactions and non-
complying Members, and reserve the right to deny access to such
Users at any time or cancel any orders placed by them in future.</p>
</div>
        </div>
        </div>
        <Footer />

        </div>

  )
}

export default refund