import React from 'react'
import HomeThreeNavBar from '../HomeThree/HomeThreeNavBar/HomeThreeNavBar'
import Footer from '../../components/Shared/Footer'

function legalpolicy() {
  return (
    <div>
        <HomeThreeNavBar />

<div className='main-div' style={{margin:'20px 20px'}}>

<div style={{textAlign:'center'}}><h2>Privacy Policy</h2></div>

<div>
    <p><span><b>WELLIO TECH PRIVATE LIMITED (Wellio Tech),</b>a Company registered
under the Companies Act 2013 and having its registered office at 402,
Ankur Building, DSK Raanwara, NDA-Pashan Road, Pune, Maharashtra,
India 411021, hereinafter referred to as the “Company” (where such
expression shall, unless repugnant to the context thereof, be deemed to
include its respective legal heirs, representatives, administrators, permitted
successors and assigns).</span></p>
<p>The creator of this Privacy Policy ensures a steady commitment to Your
privacy with regard to the protection of your invaluable information. This
privacy policy contains information about the  <b>  <a href='https://api.treatsmart.in'>https://api.treatsmart.in</a>  
</b>
   ( hereinafter referred to as
the “Platform”). In order to provide you with our uninterrupted use of
services, we may collect and, in some circumstances, disclose information
about you with your permission. To ensure better protection of your privacy,
we provide this notice explaining our information collection and disclosure
policies, and the choices you make about the way your information is
collected and used.
In order to provide you with our uninterrupted use of services, we may
collect and, in some circumstances, disclose information about you with
your permission. To ensure better protection of your privacy, we provide
this notice explaining our information collection and disclosure policies, and
the choices You make about the way Your information is collected and
used.</p>
<p>
    <b>This Privacy Policy shall be in compliance with the General Data
Protection Regulation (GDPR), which is in effect from May 25, 2018,
and any and all provisions that may read to the contrary shall be
deemed to be void and unenforceable as of that date. If you do not
agree with the terms and conditions of our Privacy Policy, including
in relation to the manner of collection or use of your information,
please do not use or access the Site. If you have any questions or
concerns regarding this Privacy Policy, you should contact our
Customer Support at support@treatsmart.in</b>
<p><b>1. DEFINITIONS</b>

<ol>
    <li>
    a. “We”, “Our”, and “Us” shall mean and refer to the Domain
    and/or the Company, as the context so requires.</li>
    <li>
    b. “You”, “Your”, “Yourself”, “User”, shall mean and refer to
natural and legal individuals who use the Platform and who is
competent to enter into binding contracts, as per Indian laws.
    </li>
    <li>
    c. Services” shall refer to providing a platform which connects
Users with specialist doctors/hospitals/providers and acts as an
aggregator selling treatment packages, Health Check
Packages, Wellness packages, Spa Packages, Gym
Packages.
    </li>
    <li>
    d. “Third Parties” refer to any Application, company or individual
    apart from the User, Vendor and the creator of this Application.
    </li>
    <li>
    e. The term “Platform” refers to the Website/Domain created by
the Company which provides the ability to User/Client to avail
services of the Company thru the use of the platform.
    </li>
    <li>
    f. “Personal Information” shall mean and refer to any personally
identifiable information that we may collect from you such as
Name, Email Id, Mobile number, Date of Birth, Password,
Photo etc.
    </li>
    <li>
    g. The term “Platform” refers to the Website/Domain created by
the Company which provides the Client to avail services of the
Company thru the use of the platform.
    </li>
    <li>
    h. &quot;Client/Member/Patient/User/Payer/Consumer/Customer&quot;
means a person, who completes the registration process and
avails the services of the Platform in connecting with doctors or
purchasing the wellness or health packages, offered on the
Platform.
    </li>
    <li>
    i. “Healthcare Providers / Hospitals/Doctors / Clinic/ Diagnostic
centre / Spa/ Gym” refers to those entities listed on the
Platform and from whom patients can choose to avail the
services.
    </li>
    </ol></p>
    <p><b>2. INFORMATION WE COLLECT</b> <p>We are committed to respecting your online privacy. We further
recognize your need for appropriate protection and management of
any Personal Information You share with us. We may collect the
following information:

<ol>
    <li>a. First name, last name, mobile number, email ID, date of birth,
    gender, payment transaction details of the Users.</li>
    <li>
    b. Tracking Information such as, but not limited to, the IP address
of your device and Device ID when connected to the Internet.
This information may include the URL that you just came from
(whether this URL is on the Platform or not), which URL you
next go to (whether this URL is on the Platform or not), your
computer or device browser information, and other information
associated with your interaction with the Platform;
    </li>
    <li>
    c. Details of Platform usage for analytics.
This privacy policy also applies to data we collect from users who are
not registered as members of this Platform, including, but not limited
to, browsing behaviour, pages viewed etc. We also collect and store
personal information provided by you from time to time on the
Platform. We only collect and use such information from you that we
consider necessary for achieving a seamless, efficient and safe
experience, customized to your needs including:
d. To enable the provision of services opted for by you;
e. To enable the viewing of content in your interest;
f. To communicate the necessary account and service-related
information from time to time;
g. To allow you to receive quality customer care services and
data Collection;
h. To comply with applicable laws, rules and regulations;
    </li>
  
</ol>  Where any service requested by you involves a third party, such
information as is reasonably necessary by the Company to carry out
your service request may be shared with such third party. We also do
use your contact information to send you offers based on your
interests and prior activity and also to view the content preferred by
you. The Company may also use contact information internally to
direct its efforts for service improvement but shall immediately delete
all such information upon withdrawal of your consent for the same
through the ‘unsubscribe’ button or through an email to be sent to
support@treatsmart.in
To the extent possible, we provide you with the option of not
divulging any specific information that you wish for us not to collect,
store or use. You may also choose not to use a particular service or
feature on the Platform and opt-out of any non-essential
communications from the platform.
Further, transacting over the internet has inherent risks which can
only be avoided by you following security practices yourself, such as
not revealing account/login related information to any other person
and informing our customer care team about any suspicious activity
or where your account has/may have been compromised.
</p></p>
<p><b>3. OUR USE OF YOUR INFORMATION</b>
<p>The information provided by you shall be used to provide and
improve the service for you and all users.

<ol>
    <li>a. For maintaining an internal record.</li>
    <li>
    b. For enhancing the Services provided.
    </li>
    
</ol></p>

</p>
<p><b>4. HOW INFORMATION IS COLLECTED</b>
<p>Before or at the time of collecting personal information, we will
identify the purposes for which information is being collected. If the
same is not identified to you, you have the right to request the
Company to elucidate the purpose of collection of said personal
information, pending the fulfilment of which you shall not be
mandated to disclose any information whatsoever.
We will collect and use your personal information solely with the
objective of fulfilling those purposes specified by us, within the scope
of the consent of the individual concerned or as required by law. We
will only retain personal information as long as necessary for the
fulfilment of those purposes. We will collect personal information by
lawful and fair means and with the knowledge and consent of the
individual concerned.
Personal data should be relevant to the purposes for which it is to be
used, and, to the extent necessary for those purposes, should be
accurate, complete, and up-to-date.</p></p>
<p><b>5. EXTERNAL LINKS ON THE PLATFORM</b><p>
The Platform may include advertisements, hyperlinks to other
websites, applications, content or resources. We have no control
over any websites or resources, which are provided by companies or
persons other than us. You acknowledge and agree that we are not
responsible for the availability of any such external sites or
resources, and do not endorse any advertising, services/products or
other materials on or available from such platform or resources. You
acknowledge and agree that we are not liable for any loss or damage
which may be incurred by you as a result of the availability of those
external sites or resources, or as a result of any reliance placed by
you on the completeness, accuracy or existence of any advertising,
products or other materials on, or available from, such websites or
resources. These external websites and resource providers may
have their own privacy policies governing the collection, storage,
retention and disclosure of Your Personal Information that You may
be subject to. We recommend that you enter the external Website
and review their privacy policy.</p></p>
<p><b>6. GOOGLE ANALYTICS</b></p>
<p>We use Google Analytics to help us to understand how you make
use of our content and work out how we can make things better.
These cookies follow your progress through our platform, collecting
anonymous data on where you have come from, which pages you
visit, and how long you spend on the site. This data is then stored by
Google in order to create reports. These cookies do not store your
personal data.
The information generated by the cookie about your use of the
Website, including your IP address, may be transmitted to and stored
by Google on servers in the United States. Google may use this
information for the purpose of evaluating your use of the website,
compiling reports on website activity for us and providing other
services relating to website activity and internet usage. Google may
also transfer this information to third parties where required to do so
by law, or where such third parties process the information on
Google&#39;s behalf. Google will not associate your IP address with any
other data held by Google. By using this website, you consent to the
processing of data about you by Google in the manner and for the
purposes set out above.
The Google website contains further information about Analytics and
a copy of Google&#39;s privacy policy pages.</p>
<p><b>7. COOKIES</b> <p>We use data collection devices such as “cookies” on certain pages of
our Websites. “Cookies” are small files stored on your hard drive that
assist us in providing customized services. We also offer certain
features that are only available through the use of a “cookie”.
Cookies can also help us provide information which is targeted to
your interests. Cookies may be used to identify logged in or
registered users. Our Website uses session cookies to ensure that
you have a good, seamless experience. These cookies contain a
unique number, your &#39;session ID&#39;, which allows our server to
recognize your computer and &#39;remember&#39; what you&#39;ve done on the
site. The benefits of this are:
a. You only need to log in once if you&#39;re navigating secure areas
of the site
b. Our server can distinguish between your computer and other
users, so you can see the information only that you have
requested.
You can choose to accept or decline cookies by modifying your
browser settings if you prefer. This may prevent you from taking full
advantage of the Website. We also use various third-party cookies
for usage, behavioural, analytics and preferences data. The following
are the different types of cookies used on the Website:
c. Authentication cookies: To identify the user and share the
content that he or she requested.
d. Functionality cookies: For customized user experience and
resuming past course progress.
e. Tracking, optimization, and targeting cookies: To capture
usage metric on the device, operating system, browser, etc. To
capture behavioural metrics for better content delivery. To cater
and suggest most suited services.</p></p>
<p><b>8. YOUR RIGHTS</b><p>
Unless subject to an exemption, you have the following rights with
respect to your personal data: 
<ol><li>
a. The right to request a copy of your personal data which we
hold about you;</li>
<li>
b. The right to request for any correction to any personal data if it
is found to be inaccurate or out of date;</li>
<li>
c. The right to withdraw your consent to the processing at any
time;</li>
<li>d. The right to object to the processing of personal data;</li>
<li>
e. The right to lodge a complaint with a supervisory authority.</li>
<li>
f. The right to obtain information as to whether personal data are
transferred to a third country or to an international organization.</li></ol>

Where you hold an account with any of our services, you are entitled
to a copy of all personal data which we hold in relation to you. You
are also entitled to request that we restrict how we use your data in
your account when you log in.</p></p>

<p><b>9. CONFIDENTIALITY</b> <p>You further acknowledge that the Platform may contain information
which is designated confidential by us and that you shall not disclose
such information without our prior written consent. Your information is
regarded as confidential and therefore will not be divulged to any
third party, unless if legally required to do so to the appropriate
authorities. We will not sell, share, or rent your personal information
to any third party or use your e-mail address for unsolicited mail. Any
emails sent by us will only be in connection with the provision of
agreed services, and you retain sole discretion to seek for
discontinuation of such communications at any point of time.</p></p>
<p><b>10. OTHER INFORMATION COLLECTORS</b> <p>Except as otherwise expressly included in this Privacy Policy, this
document only addresses the use and disclosure of information we
collect from you. To the extent that you disclose your information to
other parties, whether they are on our Platform or on other sites
throughout the Internet, different rules may apply to their use or
disclosure of the information you disclose to them. To the extent that
we use third party advertisers, they adhere to their own privacy
policies. Since we do not control the privacy policies of the third
parties, you are entitled to ask questions before you disclose your
personal information to others.</p></p>
<p><b>11. OUR DISCLOSURE OF YOUR INFORMATION</b> <p>We may host surveys for survey creators for our platform who are the
owners and users of your survey responses. We do not own or sell
your responses. Anything you expressly disclose in your responses
will be disclosed to survey creators. Please contact the survey
creator directly to better understand how they might share your
survey responses.
Information collected will not be considered as sensitive if it is freely
available and accessible in the public domain or is furnished under

the Right to Information Act, 2005, any rules made thereunder or any
other law for the time being in force.
Due to the existing regulatory environment, we cannot ensure that all
of your private communications and other personally identifiable
information will never be disclosed in ways not otherwise described in
this Privacy Policy. By way of example (without limiting and
foregoing), we may be forced to disclose information to the
government, law enforcement agencies or third parties. Therefore,
although we use industry-standard practices to protect your privacy,
we do not promise, and you should not expect, that your personally
identifiable information or private communications would always
remain private. We do however assure you that any and all
disclosure of your personally identifiable information shall be
personally intimated to you through an email sent to your provided
email address.
As a matter of policy, we do not sell or rent any personally identifiable
information about you to any third party. However, the following
describes some of the ways that your personally identifiable
information may be disclosed: <ol>
    <li>
    a. External Service Providers: There may be a number of services
offered by external service providers that help you use our
Platform. If you choose to use these optional services, and in
the course of doing so, disclose information to the external
service providers, and/or grant them permission to collect
information about you, then their use of your information is
governed by their privacy policy.</li>
<li>
b. Law and Order: We cooperate with law enforcement inquiries,
as well as other third parties to enforce laws, such as
intellectual property rights, fraud and other rights. We can (and
you authorize us to) disclose any information about you to law
enforcement and other government officials as we, in our sole
discretion, believe necessary or appropriate, in connection with
an investigation of fraud, intellectual property infringements, or
any other activity that is illegal or may expose us or you to legal
liability.</li></ol></p></p>
<p><b>12. ACCESSING, REVIEWING AND CHANGING YOUR
PROFILE</b>
<p>
Following registration, you can review and change the information
you submitted at the stage of registration, except Email ID and
mobile number. An option for facilitating such change shall be
present on the Platform and such change shall be facilitated by the
User. If you change any information, we may or may not keep track

of your old information. We will not retain in our files information you
have requested to remove for certain circumstances, such as to
resolve disputes, troubleshoot problems and enforce our terms and
conditions. Such prior information shall be completely removed from
our databases, including stored ‘back up’ systems. If you believe that
any information, we are holding on you is incorrect or incomplete, or
to remove your profile so that others cannot view it, the User needs
to remediate, and promptly correct any such incorrect information.</p></p>
<p><b>13. CONTROL OF YOUR PASSWORD</b><p>You are entirely responsible for maintaining the confidentiality of your
password. It is important that you protect it against unauthorized
access of your account and information by choosing your password
carefully and keeping your password and computer secure by signing
out after using our services.
You agree not to use the account, username, email address or
password of another member at any time or to disclose your
password to any third party. You are responsible for all actions taken
with your login information and password, including fees. If you lose
control of your password, you may lose substantial control over your
personally identifiable information and may be subject to legally
binding actions taken on your behalf. Therefore, if your password has
been compromised for any reason, you should immediately change
your password. You agree to notify us immediately if you suspect any
consistent unauthorized use of your account or access to your
password even after changing it.</p></p>
<p><b>14. SECURITY</b><p>
We treat data as an asset that must be protected against loss and
unauthorized access. We employ many different security techniques
to protect such data from unauthorized access by members inside
and outside the Company. We follow generally accepted industry
standards to protect the Personal Information submitted to us and
information that we have accessed.
However, as effective as encryption technology is, no security system
is impenetrable. Our Company cannot guarantee the security of our
database, nor can we guarantee that information you provide won’t
be intercepted while being transmitted to the Company over the
Internet.</p></p>
<p><b>15. SEVERABILITY</b> <p>Each paragraph of this Privacy Policy shall be and remain separate
from and independent of and severable from all and any other
paragraphs herein except where otherwise expressly indicated or
indicated by the context of the agreement. The decision or
declaration that one or more of the paragraphs are null and void shall
have no effect on the remaining paragraphs of this privacy policy.</p></p>
<p><b>16. AMENDMENT</b><p>
Our Privacy Policy may change from time to time. The most current
version of the policy will govern our use of your information and will
always be at the Platform. Any amendments to this Policy shall be
deemed as accepted by the User on their continued use of the
Platform.</p></p>
<p><b>17. AUTOMATED DECISION MAKING</b><p>As a responsible Company, we do not use automatic decision-
making or profiling.</p></p>
<p><b>
18. CONSENT WITHDRAWAL, DATA DOWNLOAD &amp; DATA
REMOVAL REQUESTS</b> <p>To withdraw your consent, or to request the download or delete your
data with us for any or all our services at any time, please email to
support@treatsmart.in</p></p>
<p><b>19. CONTACT US</b> <p>If you have any questions or concerns regarding this privacy policy,
you should contact us by sending an e-mail to please email to
support@treatsmart.in</p> </p>Information provided on the website may not be 100% accurate and may
be provided for promotional purposes of the business
</p>


</div>

</div>
<Footer />

    </div>
  )
}

export default legalpolicy