import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Shared/Footer";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";

const NotFound = () => {
  return (
    <>
      {/* Include navigation bar */}
      <HomeThreeNavBar />

      {/* Main section for the 404 error message */}
      <div className="pt-120 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 offset-xl-2">
              <div className="error-404 not-found mb-20">
                <div className="error-404-content text-center">
                  {/* Display 404 error code */}
                  <h1 className="error-404-title"> 404 </h1>
                  {/* Display error message */}
                  <h2 className="error-title">Page not found</h2>
                  {/* Provide additional information about the error */}
                  <p className="err-text">
                    Oops! The page you are looking for does not exist. It might
                    have been moved or deleted.{" "}
                  </p>
                  {/* Link to redirect back to the homepage */}
                  <Link to="/" className="primary_btn btn-icon ml-0">
                    <span>+</span>BACK TO HOME
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Include footer */}
      <Footer />
    </>
  );
};

export default NotFound;
