import React from "react";
import "./AboutUsArea.css";

function AboutUsArea() {
  return (
    <div className="about-us-container">
      <div className="about-us-header">
        <h3>About Us</h3>
      </div>
      <div className="about-us-content">
        <p>
          Welcome to the world’s first, largest and technology-driven healthcare
          marketplace for inpatient surgical procedures – TreatSmart!
        </p>
        <p>
          From the smallest of procedures such as root canal to the most complex
          ones such as CABG or transplants, TreatSmart helps you find the right
          hospital/healthcare provider and doctor, at the right location, and,
          most important, the right price. Our AI-driven platform lets millions
          of users – who have been recommended some procedure to undergo –
          search from hundreds of options across the city or country and make
          the right decision about which hospital and doctor they would like to
          undertake the procedure with. From getting a second opinion by
          connecting to a particular doctor at a particular hospital to actually
          booking the procedure and then reviewing and rating the doctor and the
          hospital, the patient can do pretty much everything online on our tech
          platform, anywhere, anytime, on any device, and in any language.
        </p>
        <p>
          This provides the patient and their relatives one of the easiest, most
          effective and trustworthy end- to-end experience in choosing the right
          provider and specialist for their healthcare needs.
        </p>
        <p>
          TreatSmart is founded by a stellar team of technologists,
          entrepreneurs, and senior healthcare professionals.
        </p>
      </div>

      <div className="founders-container">
        <div className="founder-card">
          <img
            src="https://th.bing.com/th/id/OIP.MCE5wawaPpskr1JEp1f1ngHaHa?rs=1&pid=ImgDetMain"
            alt="Sanjay Suryadevra"
            className="founder-photo"
          />
          <h6>Sanjay Suryadevra</h6>
          <h6>Founder & CEO</h6>
          <p>
            Technology professional with over 25 years’ experience building
            technology organisations and platforms.
          </p>
        </div>
        <div className="founder-card">
          <img
            src="https://cdn-icons-png.flaticon.com/512/8981/8981401.png"
            alt="Sheetal Jadhav"
            className="founder-photo"
          />
          <h4>Sheetal Jadhav</h4>
          <h6>Co-founder & CTO</h6>
          <p>
            Technology entrepreneur with over 20 years’ experience architecting
            and building large-scale software platforms.
          </p>
        </div>
        <div className="founder-card">
          <img
            src="https://th.bing.com/th/id/OIP.WumrUbCBZRB17Cvo3lpL0AHaG6?rs=1&pid=ImgDetMain"
            alt="Dr. Ajay Mehra"
            className="founder-photo"
          />
          <h4>Dr. Ajay Mehra</h4>
          <h6>Co-founder, Neurologist</h6>
          <p>
            Senior Neurologist with over 30 years’ experience running a
            multi-specialty hospital.
          </p>
        </div>
        <div className="founder-card">
          <img
            src="https://cdn-icons-png.flaticon.com/512/2317/2317984.png"
            alt="Dr. Yogesh Salphale"
            className="founder-photo"
          />
          <h4>Dr. Yogesh Salphale</h4>
          <h6>Co-founder, Orthopedic Surgeon</h6>
          <p>
            Senior Orthopedic and Trauma surgeon with over 25 years’ experience
            running a multi-specialty hospital.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutUsArea;
