import { useState, useEffect } from "react";

const useTransactionData = (url) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      const transactions = result.result;
      setData(transactions);

      // Extract columns
      if (transactions.length > 0) {
        const cols = Object.keys(transactions[0]).map((key) => ({
          Header: key
            .replace(/_/g, " ")
            .replace(/\b\w/g, (char) => char.toUpperCase()),
          accessor: key,
        }));
        setColumns(cols);
      }
    } catch (err) {
      setError("An error occurred while fetching transaction data!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [url]);

  return { data, columns, loading, error };
};

export default useTransactionData;
