import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import useGlobalContext from "../../../hooks/useGlobalContext";
import { useEffect } from "react";
import "./HomeThreeNavBar.css";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HomeThreeNavBar = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { stickyMenu } = useGlobalContext();
  const patient_id = localStorage.getItem("patient_id");
  const sessionKey = localStorage.getItem("sessionKey");
  const sessionExpirationTime = localStorage.getItem("sessionExpirationTime");
  const username = localStorage.getItem("username");
  const provider_id = localStorage.getItem("provider_id");
  const name = localStorage.getItem("name");
  const userRole = localStorage.getItem("userRole");

  console.log("session=====>", sessionExpirationTime);
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem("patient_id");
    localStorage.removeItem("username");
    localStorage.removeItem("provider_id");
    localStorage.removeItem("name");
    localStorage.removeItem("sessionKey");
    localStorage.removeItem("sessionExpirationTime");
    localStorage.removeItem("userRole");
    navigate("/");
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//translate.google.com/translate_a/element.js?cb=initGoogleTranslate";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  // Define the function once the Google Translate script is loaded
  window.initGoogleTranslate = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL,
      },
      "google_translate_element"
    );
    const translateElementContainer = document.getElementById(
      "google_translate_element"
    );
  };

  const handleLogoClick = () => navigate("/");
  const handleAboutUsClick = () => navigate("/aboutUs");
  const handleContactClick = () => navigate("/contact");
  const goToProviderPage = () => navigate("/ProviderLandingPage");
  const goToPatientPage = () => navigate("/patientprofile");
  const onLogin = () => navigate("/login");
  const goToAdminProfile = () => navigate("/adminProfile");

  return (
    <>
      <header>
        <div className="header-area">
          <div className="header-logo">
            <img
              src="img/logo/treatsmart-logo.jpg"
              alt=""
              onClick={handleLogoClick}
            />
          </div>
          <div className="menu-bar-area">
            {/* TO DO: Here the UI of Google Translation box needs to be fixed, it is not displaying in small screen */}
            <div className="google-translate-area-mini">
              {/* <div id="google_translate_element"></div> */}
            </div>
            <div className="profile-icon-area">
              {provider_id !== null && (
                <>
                  <div className="profile-icon" onClick={goToProviderPage}>
                    <i className="fs-5 fas fa-hospital-user"></i>
                  </div>
                </>
              )}
              {patient_id !== null && (
                <>
                  <div className="profile-icon" onClick={goToPatientPage}>
                    <i className="fs-5 fas fa-hospital-user"></i>
                  </div>
                </>
              )}
              {userRole !== null && (
                <>
                  <div className="profile-icon" onClick={goToAdminProfile}>
                    <i className="fs-5 fas fa-hospital-user"></i>
                  </div>
                </>
              )}
            </div>
            <div onClick={handleShow} className="menu-icon-area">
              <button className="side-toggle border-0 bg-transparent">
                <i className="fas fa-bars"></i>{" "}
              </button>
            </div>
          </div>
          <div className="header-navbar-items">
            <ul>
              <li onClick={handleLogoClick}>Home</li>

              <li onClick={handleContactClick}>Contact</li>

              <li onClick={handleAboutUsClick}>About Us</li>
            </ul>
          </div>
          <div className="header-contact-info">
            <div className="email-area">
              {/* <img src="img/cta/message-icon.png" alt="" /> */}
              <h5>
                <i class="fa fa-envelope" aria-hidden="true"></i>
                info@treatsmart.in
              </h5>
            </div>
            <div className="phone-area">
              {/* <img src="img/cta/phone-icon.png" alt="" /> */}
              {/* <FontAwesomeIcon icon="fa-solid fa-phone" /> */}

              <h5>
                <i class="fa fa-phone-square" aria-hidden="true"></i>
                +91 7499893791
              </h5>
            </div>
          </div>
          <div className="auth-button-area">
            <div className="profile-icon-area">
              {provider_id !== null && (
                <>
                  <div className="profile-icon" onClick={goToProviderPage}>
                    <i className="fs-5 fas fa-hospital-user"></i>

                    <h5>{name}</h5>
                  </div>
                </>
              )}
              {patient_id !== null && (
                <>
                  <div className="profile-icon" onClick={goToPatientPage}>
                    <i className="fs-5 fas fa-hospital-user"></i>

                    <h5>{username}</h5>
                  </div>
                </>
              )}
              {userRole !== null && (
                <>
                  <div className="profile-icon" onClick={goToAdminProfile}>
                    <i className="fs-5 fas fa-hospital-user"></i>

                    <h5>{username}</h5>
                  </div>
                </>
              )}
            </div>
            <div className="auth-button">
              <div className="login-logout-button">
                {provider_id === null &&
                  patient_id === null &&
                  userRole === null && (
                    <button onClick={onLogin} className="login-button">
                      <i className="fs-5 fas fa-sign-in"></i>
                      <h5>Login</h5>
                    </button>
                  )}

                {provider_id !== null && (
                  <button onClick={onLogout} className="logout-button">
                    <i className="fs-5 fas fa-sign-out"></i>
                    Logout
                  </button>
                )}
                {patient_id !== null && (
                  <button onClick={onLogout} className="logout-button">
                    <i className="fs-5 fas fa-sign-out"></i>
                    Logout
                  </button>
                )}
                {userRole !== null && (
                  <button onClick={onLogout} className="logout-button">
                    <i className="fs-5 fas fa-sign-out"></i>
                    Logout
                  </button>
                )}
              </div>
              <div className="google-translate-area">
                <div id="google_translate_element"></div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Sidebar show={show} handleClose={handleClose} />
    </>
  );
};

export default HomeThreeNavBar;
