import React, { useState, useEffect } from "react";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import Footer from "../../components/Shared/Footer";
import PatientLandingPageArea from "./PatientLandingPageArea/PatientLandingPageArea";
import HomeThreeAboutArea from "../HomeThree/HomeThreeAboutArea/HomeThreeAboutArea";
import { useLocation } from "react-router-dom";

function PatientLandingPage() {
  const [data, setData] = useState();

  // Get the state passed from the previous route
  const { state } = useLocation();

  // Use useEffect to set data when the component mounts or state changes
  useEffect(() => {
    if (state) {
      setData(state);
    }
  }, [state]);

  return (
    <div>
      {/* Include navigation bar */}
      <HomeThreeNavBar />
      {/* Include patient landing page area component */}
      <PatientLandingPageArea />
      {/* Include about area component */}
      <HomeThreeAboutArea />
      {/* Include footer */}
      <Footer />
    </div>
  );
}

export default PatientLandingPage;
