import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Card } from "react-bootstrap";
import "./BookingHistoryBox.css";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const ServicesTwoSingleItem = (bookingData) => {
  const navigate = useNavigate();
  const status = "complete";

  console.log("p2==>", bookingData.bookingData);
  const handleReviewClick = () => {
    navigate("/Review", {
      state: {
        patientId: bookingData.bookingData.patient_id,
        procedureId: bookingData.bookingData.procedure_id,
      },
    });
  };
  let price = bookingData.bookingData.price;

  async function onPay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const data = await fetch("https://api.treatsmart.in/procedurebookingpayment", {
      method: "POST",
      timeout: 0,
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: price * 100,
        patient_id: localStorage.getItem("patient_id"),
      }),
    });

    console.log(data);

    const options = {
      key: "rzp_test_LerHhmnSru6RuL",
      amount: price * 100,
      currency: "INR",
      order_id: data.id,
      handler: function (response) {
        alert(response.razorpay_payment_id);
        alert(response.razorpay_order_id);
        alert(response.razorpay_signature);
        onProcedureUpdate("complete", bookingData.bookingData.id);
        navigate("/bookhistory");
        window.location.reload();
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const onProcedureUpdate = (status, bookingId) => {
    console.log("the status and id ----->", status, bookingId);
    axios
      .put(`https://api.treatsmart.in/api/patients/booking/update/${bookingId}`, {
        status,
      })
      .then((response) => {
        console.log("Booking successful:", response.data);
      })
      .catch((error) => {
        console.error("Error booking procedure:", error);
      });
  };

  return (
    <div className="booking-history-box service-box-3">
      <div className="box-image-area">
        <img src={bookingData.bookingData.procedure_image} alt="" />
      </div>

      <div className="box-details-area">
        <h5>{bookingData.bookingData.pname}</h5>
        <h6>Price: {bookingData.bookingData.price}</h6>
        <h6>Status: {bookingData.bookingData.status}</h6>

        {bookingData.bookingData.status === "complete" ? (
          <div onClick={handleReviewClick} className="service-link">
            <a href="#">Review</a>
          </div>
        ) : (
          <div onClick={onPay} className="service-link">
            <a href="#">Pay Now</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServicesTwoSingleItem;
