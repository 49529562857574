import React, { useState, useEffect } from "react";
import axios from "axios";
import "../ProcedureAccomodation/ProcedureAccomodation.css";

const ProcedureOptions = ({
  procedureId,
  number,
  condition = false,
  fistClassAdd,
  secondClassAdd,
  setOptionPrice,
}) => {
  console.log("Procedure ID to show options in procedure is:", procedureId);
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState(false); // New state for tracking option selection

  useEffect(() => {
    axios
      .get(`https://api.treatsmart.in/procedure/option/${procedureId}`)
      .then((response) => {
        const fetchedOptions = response.data;
        setOptions(fetchedOptions);
        console.log("Procedure options data:", fetchedOptions);

        // Check for special option names and auto-select if found
        const specialOption = fetchedOptions.find(
          (option) =>
            option.option_name === "NA" ||
            option.option_name === "N/A" ||
            option.option_name === "NULL"
        );

        if (specialOption) {
          setSelectedOption(specialOption.option_name);
          setOptionPrice(parseFloat(specialOption.price));
          setIsOptionSelected(true);
          console.log("Auto-selected option:", specialOption.option_name);
          console.log("Auto-selected option price:", specialOption.price);
        }
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }, [procedureId, setOptionPrice]);

  const handleOptionChange = (event) => {
    const selectedOptionName = event.target.value;
    const selectedOptionData = options.find(
      (option) => option.option_name === selectedOptionName
    );

    setSelectedOption(selectedOptionName);

    if (selectedOptionData) {
      const selectedOptionPrice = parseFloat(selectedOptionData.price);
      setOptionPrice(selectedOptionPrice);
      setIsOptionSelected(true); // Set the state to true when an option is selected
      console.log("setOptionPrice", selectedOptionPrice);
    }
  };

  const handleClearSelection = () => {
    setSelectedOption("");
    setOptionPrice(0); // Reset the option price
    setIsOptionSelected(false); // Reset the selection state
  };

  return (
    <>
      <div className="card">
        <div className="card-header" id={`heading${number}`}>
          <h5 className="mb-0">
            <a
              href="#"
              className={`btn-link ${fistClassAdd && fistClassAdd}`}
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${number}`}
              aria-controls={`collapse${number}`}
              aria-expanded={condition}
            >
              Procedure Options
            </a>
          </h5>
        </div>
        <div
          id={`collapse${number}`}
          className={`collapse ${secondClassAdd && secondClassAdd}`}
          aria-labelledby={`heading${number}`}
          data-parent="#accordion"
        >
          <div className="card-body">
            <form>
              {options.length > 0 ? (
                options.map((option) => (
                  <label key={option.option_id} className="radio-label">
                    <div className="radio-left">
                      <input
                        type="radio"
                        name="option"
                        value={option.option_name}
                        checked={selectedOption === option.option_name}
                        onChange={handleOptionChange}
                      />
                      <p>{option.option_name}</p>
                    </div>
                    <div className="radio-right">
                      <p>₹ {option.price}</p>
                    </div>
                  </label>
                ))
              ) : (
                <p>No options available</p>
              )}
            </form>
            {isOptionSelected && (
              <button className="btn btn-danger" onClick={handleClearSelection}>
                Clear Selection
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcedureOptions;
