import React from 'react';
import HomeThreeNavBar from '../HomeThree/HomeThreeNavBar/HomeThreeNavBar';
import Footer from '../../components/Shared/Footer';

function conditions() {
  return (
    <div>
        < HomeThreeNavBar />
    <div style={{margin:'20px 20px'}}>
        <h2 className='text-center'>TERMS AND CONDITIONS OF SERVICE AND USE</h2>
        <p>We, WELLIO TECH PRIVATE LIMITED (Wellio Tech), a Company
registered under the Companies Act 2013 and having its registered office
at 402, Ankur Building, DSK Raanwara, NDA-Pashan Road, Pune,
Maharashtra, India 411021, hereinafter referred to as
the “Company” (where such expression shall, unless repugnant to the
context thereof, be deemed to include its respective legal heirs,
representatives, administrators, permitted successors and assigns).
</p>
<p>The Company ensures steady commitment to your usage of the Platform
and privacy with regard to the protection of your valuable information. This
document contains information about the Website and domain <a href="https://www.treatsmart.in">https://www.treatsmart.in</a> (hereinafter referred to as the “Platform”).</p>
<p>For the purpose of these Terms of Use (“Terms”), wherever the context so
requires,</p>
<ol typeof='1'>
        <li><b>1. DEFINITIONS</b>
          <ol typeof="a">
            <li>a. “We”, “Our”, and “Us” shall mean and refer to the Domain
            and/or the Company, as the context so requires.</li>
            <li>b. “You”, “Your”, “Yourself”, “User”, shall mean and refer to
natural and legal individuals who use the Platform and who is
competent to enter into binding contracts, as per Indian laws.</li>
<li>c. Services” shall refer to providing a platform which connects
Users with specialist doctors/hospitals/healthcare providers
and acts as an aggregator selling treatment packages, Health
Check Packages, Wellness packages, Spa Packages, Gym
Packages. The detailed explanation for the same shall be
provided in Clause 3 of these Terms of Use.</li>
<li>d. “Third Parties” refer to any Application, company or individual
apart from the User, Vendor and the creator of this Platform. It
shall include such payment gateways as partnered by the
Company.</li>
<li>e. The term “Platform” refers to the Website/Domain created by
the Company which provides the Client to avail services of the
Company thru the use of the platform.</li>
<li>f. <b>&quot;Member/Patient/User&quot;</b> means a person, who completes the
registration process and avails the services of the Platform in
connecting with doctors or purchasing the wellness or health
packages, offered on the Platform.</li>
<li>g. “Hospitals/Providers/Doctors / Clinic/ Diagnostic centre / Spa/
Gym” refers to those entities listed on the Platform and from
whom patients can choose to avail the services.</li>
<li><b>h. The headings of each section in these Terms are only for
the purpose of organizing the various provisions under
these Terms in an orderly manner and shall not be used by
either Party to interpret the provisions contained herein in
any manner. Further, it is specifically agreed to by the
Parties that the headings shall have no legal or contractual
value.</b></li>
<li>i. The use of this Platform by the Members is solely governed by
these Terms as well as the Privacy Policy and other policies as
listed on the Platform, and any modifications or amendments
made thereto by the Company, from time to time, at its sole
discretion. If you continue to access and use this Platform, you
are agreeing to comply with and be bound by the following
Terms and Conditions of Use and Our Privacy Policy. The
Member expressly agrees and acknowledges that these Terms
and Policy are co-terminus in nature and that expiry/termination
of either one will lead to the termination of the other.</li>
<li>j. The Member unequivocally agrees that these Terms and the
aforementioned Policy constitute a legally binding agreement
between the Member and the Company, and that the Member
shall be subject to the rules, guidelines, policies, terms, and
conditions applicable to any service that is provided by the
Platform, and that the same shall be deemed to be
incorporated into these Terms, and shall be treated as part and
parcel of the same. The Member acknowledges and agrees
that no signature or express act is required to make these
Terms and the Policy binding on the Member and that the
Member’s act of visiting any part of the Platform constitutes the
Member’s full and final acceptance of these Terms and the
aforementioned Policy.</li>
<li>k. The Company reserves the sole and exclusive right to amend
or modify these Terms without any prior permission or
intimation to the Member, and the Member expressly agrees
that any such amendments or modifications shall come into
effect immediately. The Member has a duty to periodically
check the terms and stay updated on its requirements. If the
Member continues to use the Platform following such a
change, the Member will be deemed to have consented to any
and all amendments/ modifications made to the Terms. In so
far as the Member complies with these Terms, it is granted a
personal, non-exclusive, non-transferable, revocable, limited privilege to access and use the Platform and the Services. If
the Member does not adhere to the changes, the Member must
stop using the Services at once. Your continued use of the
Services will signify your acceptance of the changed terms.</li>


          </ol>
        </li>
        <li><b>2. REGISTRATION</b></li>
        <ol typeof='a'>
            <li>a. Registration is not mandatory for all Members in order to
acquire information about the Platform or its features, however,
to avail the services, the Member shall be a registered user on
the platform;</li>
<p><b>General Terms</b></p>
<li>b. Registration for this Platform is available only to those above
the age of Eighteen (18) years, barring those <b>“Incompetent to
Contract”</b> which inter alia include insolvents. If you are a minor
and wish to use the Platform as a Member, You may do so
through your legal guardian and the Company reserves the
right to terminate your account on knowledge of you being a
minor and having registered on the Platform or availing any of
its Services.</li>
<li>c. Further, at any time during your use of this Platform, including
but not limited to the time of registration, you are solely
responsible for protecting the confidentiality of Your User name
and password, and any activity under the account shall be
deemed to have been done by you. In the case that you
provide us with false and/or inaccurate details or we have
reason to believe you have done so, we hold the right to
permanently suspend your account. You agree that you will not
disclose your password to any third party and that you will take
sole responsibility for any activities or actions under your
account, whether or not you have authorized such activities or
actions. You will immediately notify us of any herein below use
of your account.</li>
        </ol>
        <li><b>3. PLATFORM OVERVIEW</b></li>
        <ol>
            <p>The Platform acts as an aggregator and connects the Users with
hospitals, doctors and wellness centers of their choice from among
those listed on the Platform. The following are the Services provided
by the Platform:</p>
<li>i. <b>Booking Appointment:</b> The platform facilitates booking of
preliminary consultations with the doctors/surgeons of the
member’s choice and book subsequent consultations only on
their request. The User has to click “book consultation” button
on the profile page of the medical professional on the Platform. To avail this Service, the member has to select from among
consulting hospital of the doctors/surgeons of their choice listed
on the Platform and pick a time slot of their choice from among
the available time slots. To confirm the consultation, the Users
would be required to provide the contact information requested
by the Platform. Upon receiving a request, The Company will
confirm the consultation subject to availability of the
doctor/surgeon during the selected time slot.</li>
<li>ii. <b>Booking Procedure:</b> The platform facilitates booking of surgical and
other medical procedures with the doctors/surgeons of the member’s
choice and book subsequent consultations only on their request. The User
has to click “book procedure” button on the relevant page of the website on
the Platform. To avail this Service, the member has to select from among
the medical/surgical procedures of their choice listed on the Platform and
pick a time slot of their choice from among the available time slots. To
confirm the booking of the procedure, the Users would be required to
provide the contact information requested by the Platform. Upon receiving
a request, The Company will confirm the procedure booking subject to
availability of the doctor/hospital slot as requested by the User.</li>
<li>iii. <b>Ask your Expert Opinion:</b> The Platform facilitates the User to
connect to doctors/surgeons and seek reply to their queries. To avail this
Service, the User has to provide his/her contact information including their
email address and mobile phone number along with the query they want to
raise to a doctor/surgeon. Upon successful generation of the query, the
Platform will facilitate the User in receiving a response from the
doctor/surgeon via messaging application. It is specifically stated that the
Company is not providing any medical advice and/or endorsing any specific
doctor/surgeon or medical procedure and the Company will not be
responsible for any medical advice given by any doctor/surgeon.</li>
<li>iv. <b>Get Quotations:</b> The Platform shall facilitate the Users to get an
estimated quotation of required surgery or medical procedure at various
consulting hospitals of the surgeons/doctors specializing in the specified
medical procedure in the selected city (provided the Services are available
in the selected city) from among those listed on the Platform. It is hereby
stated that the final price may vary from the quotation depending upon the
complexities in the User’s treatment, their needs and their treatment plan.</li>
<li>v. <b>Diagnostic Facility:</b> The Platform facilitates the User to avail
diagnostic tests, as required in the course of medical treatment and as
advised by the doctor/surgeons the User chooses from among those listed
on the Platform. It is hereby stated that the company does not conduct any
diagnostic tests, therefore they are not responsible for any sample
collected, tests conducted and the accuracy, authenticity and/or
correctness of diagnostic test reports.</li>
<li>vi. <b>Treatment Packages:</b> The Platform facilitates treatment packages
which can be customized by the Users in accordance with their needs.</li>
<li>vii. <b>Health Check:</b> The Platform facilitates the Users to avail packages
for the hospital/clinic/diagnostic centres listed on the Platform.</li>
<li>viii. <b>Wellness:</b> We may sell Spa and Massage packages via the platform
in the near future.</li>
<p><b>The Platform only provides aggregator services and shall not
responsible in any manner for any death, injury or any other
medico legal issues that may arise as a consequence of availing
the services, the onus for the same shall lie with the hospital
and or the doctors providing the treatment.
Any services availed which do not fall within the ambit of the
package availed will have to be paid by the User directly at the
facility.</b></p>
        </ol>
        <li><b>4. ELIGIBILITY</b></li>
        <ol>
            <li>a. The Members further represent that they will comply with this
Agreement and all applicable local, state, national and international laws,
rules and regulations.</li>
            <li>b. The Members may not use the Platform if they are not competent to
contract or are disqualified from doing so by any other applicable law, rule
or regulation currently in force.</li>
        </ol>
        CONTENT
<p>All text, graphics, Member interfaces, visual interfaces, photographs,
trademarks, logos, brand names, descriptions, sounds, music and
artwork (collectively, ‘Content’), is generated/provided by Platform
and the Platform have control over it and assures a reasonable
quality, the accuracy, integrity or genuineness of the services offered
through the Platform.</p>
<p>The Users/doctors, clinics/hospitals/wellness centres and spas shall
be solely responsible for the Content uploaded by them on the
Platform including but not limited to details of the medical
professional, hospital or specific queries and requirements. In case of
any dispute pertaining to the authenticity, accuracy or originality of
such content, the User responsible for posting the content alone shall
be responsible for the same. They shall be further responsible for
indemnifying the Company and the Platform under such
circumstances.</p>
<p>
All the Content displayed on the Platform is subject to copyright and
shall not be reused by any party (or a third party) without the prior
written consent of the Company and the copyright owner.
The Members are solely responsible for the integrity, authenticity,
quality and genuineness of the feedback and comments by Members
can be made via the Platform, the Platform bears no liability
whatsoever for any feedback or comments made by the Members or
made in respect of any of the content on the Platform. Further, the
Platform reserves its right to suspend the account of any Member for
an indefinite period to be decided at the discretion of the Platform or
to terminate the account of any Member who is found to have created
or shared or submitted any Content or part thereof that is found to be
untrue/inaccurate/misleading or offensive/vulgar. The Member shall
be solely responsible for making good any financial or legal losses
incurred through the creation/sharing/submission of Content or part
thereof that is deemed to be untrue/inaccurate/misleading.
The Members have a personal, non-exclusive, non-transferable,
revocable, limited privilege to access the Content on the Platform.
Members shall not copy, adapt, and modify any content without
written permission of the Company.</p>
      </ol>
      <p><b>CUSTOMISATION OF PACKAGE</b></p>
      <p>The Platform facilitates the Users to customise the treatment
packages that they seek to avail through the Platform for medical
treatments in accordance with their specific needs or requirements.
However, the customisation of treatment packages would have to be
abiding the following terms:</p>
<p>i. The pricing of the services offered under the customised package will
depend on the line of treatment or medical procedure you choose to
undergo, the category of post-operative care provider chosen by the User,
the treatment option chosen by the user, the category of hospital room the
User opts for, the choice of food/beverages chosen by the User, the User’s
pharmaceutical needs which shall be based on doctors/surgeons advice
and any other special facility that may be required by the User at any stage
of treatment.</p>
<p>ii. The Customisation package shall not include the following costs:
a. Any additional expenses that may be incurred due to any
complications during surgery/treatment;
b. Any additional test or diagnosis required as a result of
special nature of treatment or procedure;
c. Any transportation cost involved; and d. d. Pharmaceutical expenses incurred towards
medications not prescribed by the doctor or in cases
where medication is required beyond the period
prescribed by the doctor.</p>
<p>
iii. Any services not specified in the package will be charged additionally
and the charges for the same would have to be paid directly at the hospital.</p>
<p><b>TERM</b></p>
<p>a. These Terms shall continue to form a valid and binding
contract between the Parties and shall continue to be in full
force and effect until the Member continues to access and use
the Platforms.</p>
<p>b. The Members may terminate their use of the Platform at
any time.</p>
<p>c. The Company may terminate these Terms and close a
Member’s account at any time without notice and/or suspend or
terminate a Member’s access to the Platform at any time and
for any reason, if any discrepancy or legal issue arises.
<p>d. Such suspension or termination shall not limit our right to
take any other action against you that the Company considers
appropriate.</p>
<p>e. It is also hereby declared that the Company may
discontinue the Services and Platforms without any prior
notice.</p></p>
<p><b>TERMINATION</b></p>
<p>
a. The Company reserves the right, in its sole discretion, to
unilaterally terminate the Member’s access to the Platform, or
any portion thereof, at any time, without notice or cause.</p>
<p>b. The Platform also reserves the universal right to deny
access to particular Members, to any/all of are on its Platform
without any prior notice/explanation in order to protect the
interests of the Platform and/or other visitors to the Platform.</p>
<p>c. The Platform reserves the right to limit, deny or create
different access to the Platform and its features with respect to
different Members, or to change any of the features or
introduce new features without prior notice.</p>
<p>d. The Member shall continue to be bound by these Terms,
and it is expressly agreed to by the Parties that the Member 
shall not have the right to terminate these Terms till the expiry
of the same.
</p>
<p><b>COMMUNICTION</b></p>
<p>By using this Platform and providing his/her identity and contact
information to the Company through the Platform, the Members
hereby agree and consent to receive calls, e-mails or SMS from the
Company and/or any of its representatives at any time.</p>
<p>Clients can report to &quot;7499893791&quot; if they find any discrepancy with
regard to Platform or content-related information and the Company
will take necessary action after an investigation. The response with
resolution (if any issues found) shall be dependent on the time is
taken for investigation.</p>
<p>The Member expressly agrees that notwithstanding anything
contained hereinabove, it may be contacted by the Company or any
representatives relating to any Services availed by the Member on
the Platform or anything pursuant thereto and the Members agree to
indemnify the Company from any and all harassment claims. It is
expressly agreed to by the Parties that any information shared by the
Member with the Company shall be governed by the Privacy Policy.</p>
<p><b>PAYMENT</b></p>
<div><ol><li>a. Registration on the Platform is free of cost at present.
However, in case of availing the services, the Users shall pay
the appropriate fee for the package directly to the Company in
any of the prescribed modes of Payment Methods
i. Debit Card
ii. Credit Cards
iii. Amex Card
iv. Net Banking
v. UPI</li>

<li> b. Online Payment Gateways: RazorPay or any other
payment apps</li>
<li>
c. The User(s) acknowledges that a minimum of one of the
above payment methods shall be offered on the Platform. An
additional processing charge of 3% would be levied on the
payments made and the User agrees to the same. The Users
are solely responsible for the genuineness of credentials and
payment information provided on the Platform and the Platform

shall not be liable for any consequences, direct or indirect,
resulting from the provision of incorrect or untrue credentials or
payment information by any Users.
</li>
<li>

d. The payment is processed through a third-party gateway
and the User shall be bound by the third party’s terms and
conditions.
</li>
<li>
e. For appointments booked with doctors or any other
medical professionals booked through the Platform, the Users
are advised to make payment for the same through the
Platform.
</li>
<li>f. For any payments made through the Platform, the
Company or the Platform shall not be responsible for:
i. Lack of authorization for any transaction.
ii. Exceeding of any pre-set limit mutually agreed by the
User and their bank
iii. Any payment issues arising as a result of the
transactions.
iv. The transaction being declined for any other reason</li>
<li>
g. In the event of money getting deducted from the User’s
Account, despite a failure of transaction, the payment deducted
will be returned to the User’s account within 7 working days.
</li>
</ol>
<div>
    <p>
   <b> MEMBER OBLIGATIONS AND FORMAL UNDERTAKINGS AS TO
   CONDUCT</b>
    </p>
    <ol>
        <li>
        a. Agree to provide genuine credentials during the process
of registration on the Platform. You shall not use a fictitious
identity to register. The Company is not liable if the Member
has provided incorrect information.
        </li>
        <li>
        b. Agree to ensure the Name, Email address, Address,
Mobile number and any such other information provided during
account registration is valid at all times and shall keep your
information accurate and up-to-date. The Member can update
their details anytime through accessing their profile on the
platform.
        </li>
        <li>
        c. Agree that they are solely responsible for maintaining the
confidentiality of your account password. You agree to notify us
immediately of any unauthorized use of your account.
Company reserves the right to close your account at any time
for any or no reason.
        </li>
        <li>
        d. Understand and acknowledge that the data submitted is
manually entered into the database of the Platform. The
Member also acknowledges the fact that data so entered into
the database is for the purpose of easy and ready reference for
the Member, and to streamline the Services through the
Platform.
        </li>
        <li>
        e. Authorize the Platform to use, store or otherwise process
certain personal information and all published Content, Client
responses, Client locations, Member comments, reviews and
ratings for personalization of Services, marketing and
promotional purposes and for optimisation of Member-related
options and Services.
        </li>
        <li>
        f. Understand and agree that, to the fullest extent
permissible by law, the Platform/Company and their
successors and assigns, or any of their affiliates or their
respective officers, directors, employees, agents, licensors,
representatives, operational service providers, advertisers or
suppliers shall not be liable for any loss or damage, of any
kind, direct or indirect, in connection with or arising from use of
the Platform or from this terms of use, including, but not limited
to, compensatory, consequential, incidental, indirect, special or
punitive damages.
        </li>
    </ol>
</div>
<div>
    <p><b>4. INDEMNITY</b></p>
  <p>The Members of this Platform agree to indemnify, defend and hold
harmless the Company/Platform, and their respective directors,
officers, employees and agents (collectively, &quot;Parties&quot;), from and
against any and all losses, liabilities, claims, damages, demands,
costs and expenses (including legal fees and disbursements in
connection therewith and interest chargeable thereon) asserted
against or incurred by us that arise out of, result from, or maybe
payable by virtue of, any breach or non-performance of any
representation, warranty, covenant or agreement made or obligation
to be performed pursuant to these terms of use. Further, the Member
agrees to hold the Company/Platform harmless against any claims
made by any third party due to, or arising out of, or in connection
with:

a. Member’s use of the Platform,
b. Member’s violation of these Terms and Conditions;
c. Member’s violation of any rights of another;
d. Member’s alleged improper conduct pursuant to these
Services;
e. Member’s conduct in connection with the Platform;
Member agrees to fully cooperate in indemnifying the Company and
the Platform at the Member’s expense. The Member also agrees not
to reach a settlement with any party without the consent of the
Company.
In no event shall the Company/Platform be liable to compensate the
Member or any third party for any special, incidental, indirect,
consequential or punitive damages whatsoever, including those
resulting from loss of use, data or profits, whether or not foreseeable,
and whether or not the Company/ Platform had been advised of the
possibility of such damages, or based on any theory of liability,
including breach of contract or warranty, negligence or other tortuous
action, or any other claim arising out of or in connection with the
Member’s use of or access to the Platform and/or the Services or
materials contained therein.</p>

</div>
<div>
    <p><b>5. LIMITATION OF LIABILITY</b></p>
    <p>
    a. The Founders/ Promoters/ Directors/ Partners/
Associated people of the Company/Platform are not
responsible for any consequences arising out of the following
events:
i. If the Platform is inoperative/non-responsive due to any
connectivity errors associated with the internet
connection such as but not limited to slow connectivity,
no connectivity, server failure;
ii. If the Member has fed incorrect information or data or for
any deletion of data;
iii. If there is an undue delay or inability to communicate
through email;
iv. If there is any deficiency or defect in the Services
managed by Us;
v. If there is a failure in the functioning of any other service
provided by the Platform.
b. The Platform accepts no liability for any errors or
omissions, on behalf of itself, or for any damage caused to the
Member, the Member’s belongings, or to any third party,
resulting from the use or misuse of the Platform or any service
availed of by the Member through the Platform. The service

and any Content or material displayed on the service is
provided without any guarantees, conditions or warranties as to
its accuracy, suitability, completeness or reliability. The
Platform will not be liable to you for the unavailability or failure
of the Platform.
c. Members are to comply with all laws applicable to them
or to their activities, and with all Policies, which are hereby
incorporated into this Agreement by reference.
d. The Platform expressly excludes any liability for any loss
or damage that was not reasonably foreseeable by the
Platform and which is incurred by you in connection with the
Platform, including loss of profits; and any loss or damage
incurred by you as a result of your breach of these terms.
e. To the fullest extent permitted by law, the Platform shall
not be liable to you or any other party for any loss or damage,
regardless of the form of action or basis of any claim. You
acknowledge and agree that your sole and exclusive remedy
for any dispute with us is to terminate your use of the Platform.
    </p>
</div>
<div>
    <p><b>
        
    6. INTELLECTUAL PROPERTY RIGHTS</b></p>
    <p>
    Unless expressly agreed to in writing, nothing contained herein shall
give the Member a right to use any of the Platform’s, trademarks,
service marks, logos, domain names, information, questions,
answers, solutions, reports and other distinctive brand features, save
according to the provisions of these Terms. All logos, trademarks,
brand names, service marks, domain names, including material,
designs, and graphics created by and developed by the Platform and
other distinctive brand features of the Platform are the property of the
Company or the respective copyright or trademark owner.
Furthermore, with respect to the Platform created by the Company,
the Company shall be the exclusive owner of all the designs,
graphics and the like, related to the Platform.
The Member may not use any of the intellectual property displayed
on the Platform in any manner that is likely to cause confusion
among existing or prospective Members of the Platform, or that in
any manner disparages or discredits the Company/Platform, to be
determined in the sole discretion of the Company.
    </p>
</div>
<div>
    <p>

        <b>7. DISCLAIMER OF WARRANTIES AND LIABILITIES</b>
    </p>
    <p>a. The Member agrees and undertakes that they are
accessing the Platform at their sole risk and are that they are

using their best and prudent judgment before availing any
service listed on the Platform or accessing/using any
information displayed thereon.
b. The Member agrees that any kind of information,
resources, activities, recommendations obtained/availed from
Platform, written or oral, will not create any warranty and the
Platform disclaims all liabilities resulting from these.
c. The Company/Platform does not guarantee that the
Services contained in the Platform will be uninterrupted or
error-free, or that the Platform or its server will be free of
viruses or other harmful components, and the Member hereby
expressly accepts any and all associated risks involved with
the Member’s use of the Platform.
d. It is further agreed to by the Parties that the contents of
this Section shall survive even after the termination or expiry of
the Terms and/or Policy</p>
</div>
<div><p><b>8. JURISDICTION</b></p>
<p>The Parties to the Term hereby mutually agree that Jurisdiction for
any claim arising out of the said terms shall lie with the courts in
Pune, Maharashtra, India.</p></div>
<div><p><b>9. FORCE MAJEURE</b></p>
<p>Neither the Company nor the Platform shall be liable for damages for
any delay or failure to perform its obligations hereunder if such delay
or failure is due to cause beyond its control or without its fault or
negligence, due to Force Majeure events including but not limited to
acts of war, acts of God, earthquake, riot, fire, festive activities
sabotage, labour shortage or dispute, internet interruption, technical
failure, breakage of sea cable, hacking, piracy, cheating, illegal or
unauthorized.</p></div>
<div><p><b>10. DISPUTE RESOLUTION AND JURISDICTION</b></p>
<p>It is expressly agreed to by the Parties hereto that the formation,
interpretation and performance of these Terms and any disputes
arising therefrom will be resolved through a two-step Alternate
Dispute Resolution (“ADR”) mechanism. It is further agreed to by the
Parties that the contents of this Section shall survive even after the
termination or expiry of the Terms and/or Policy.

A. Mediation: In case of any dispute between the parties, the
Parties will attempt to resolve the same amicably amongst
themselves, to the mutual satisfaction of all Parties. In the
event that the Parties are unable to reach such an amicable
solution within thirty (30) days of one Party communicating
the existence of a dispute to any other Party, the dispute will
be resolved by arbitration, as detailed herein below;
B. Arbitration: In the event that the Parties are unable to
amicably resolve a dispute by mediation, said dispute will be
referred to arbitration by a sole arbitrator to be appointed by
the Company, and the award passed by such sole arbitrator
will be valid and binding on all Parties. The Parties shall
bear their own costs for the proceedings, although the sole
arbitrator may, in his/her sole discretion, direct either Party
to bear the entire cost of the proceedings. The arbitration
shall be conducted in English, and the seat of Arbitration
shall be Pune, Maharashtra, India.

The Parties expressly agree that the Terms of Use, Privacy Policy
and any other agreements entered into between the Parties are
governed by the laws, rules and regulations of India.</p></div>
<div><p><b>11. NOTICES/GRIEVANCES</b></p>
<p>Any and all communication relating to any dispute or grievance
experienced by the Member may be communicated to the Company
by the Member by emailing to support@treatsmart.in</p></div>
<div><p><b>12. MISCELLANEOUS PROVISIONS</b></p>
<p>A. Entire Agreement: These Terms, read with the Policy, form
the complete and final contract between the Member and
the Company with respect to the subject matter hereof and
supersedes all other communications, representations and
agreements (whether oral, written or otherwise) relating
thereto.
B. Waiver: The failure of either Party at any time to require
performance of any provision of these Terms shall in no
manner affect such Party&#39;s right at a later time to enforce the
same. No waiver by either party of any breach of these
Terms, whether by conduct or otherwise, in any one or more
instances, shall be deemed to be or construed as a further
or continuing waiver of any such breach, or a waiver of any
other breach of these Terms.

C. Severability: If any provision/clause of these Terms is held
to be invalid, illegal or unenforceable by any court or
authority of competent jurisdiction, the validity, legality and
enforceability of the remaining provisions/clauses of these
Terms shall in no way be affected or impaired thereby, and
each such provision/clause of these Terms shall be valid
and enforceable to the fullest extent permitted by law. In
such case, these Terms shall be reformed to the minimum
extent necessary to correct any invalidity, illegality or
unenforceability, while preserving to the maximum extent
the original rights, intentions and commercial expectations
of the Parties hereto, as expressed herein.</p></div>
<div><p><b>Contact Us:</b></p><p>
If you have any questions about this Policy, the practices of the Platform, or
your experience with the Service provided by the Platform, you can contact
us at support@treatsmart.in</p></div>
</div>

    </div>
    <Footer />
    </div>
  )
}

export default conditions