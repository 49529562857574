import React from "react";

const ContactArea = () => {
  return (
    <>
      <section className="contact-area">
        <div className="contact-container">
          <div className="contact text-center">
            <i className="fas fa-envelope"></i>
            <h5>Mail Here</h5>

            {/* <p>info@TreatSmart.in</p> */}
            <p>
              <a href="mailto:info@TreatSmart.in">info@TreatSmart.in</a>
            </p>
          </div>

          <div className="contact text-center">
            <i className="fas fa-map-marker-alt"></i>
            <h5>Visit Here</h5>
            {/* <p>TreatSmart Center, Bavdhan, Pune, Maharashtra, India</p> */}
            <p>
              <a
                href="https://www.google.com/maps/place/Nell+Infotech+Private+Limited/@18.5122272,73.7739643,16.5z/data=!4m14!1m7!3m6!1s0x3bc2bf1ee90a41dd:0xcff42631f7728601!2sGreen+Mart!8m2!3d18.5115993!4d73.7740887!16s%2Fg%2F11kpsld8js!3m5!1s0x3bc2bf4cf9e1d4f5:0x8c0a2438e135b261!8m2!3d18.5093749!4d73.7815947!16s%2Fg%2F11fkyxn64z?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                TreatSmart Center, Bavdhan, Pune, Maharashtra, India
              </a>
            </p>
          </div>

          <div className="contact text-center">
            <i className="fas fa-phone"></i>
            <h5>Call Here</h5>

            {/* <p>+91 7499893791</p> */}
            <p>
              <a href="tel:+917499893791">+91 7499893791</a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
