import React from 'react'
import HomeThreeNavBar from '../HomeThree/HomeThreeNavBar/HomeThreeNavBar'
import Footer from '../../components/Shared/Footer'

function disclaimerPolicy() {
  return (
    <div>
<HomeThreeNavBar />
<div style={{margin:'20px 20px'}}>

<div style={{textAlign:'center'}}>
<h2 >DISCLAIMER POLICY</h2>
</div>

<div>
    <p><span><b>WELLIO TECH PRIVATE LIMITED (Wellio Tech),</b></span>a Company registered
under the Companies Act 2013 and having its registered office at 402,
Ankur Building, DSK Raanwara, NDA-Pashan Road, Pune, Maharashtra,
India 411021, represented by its members, hereinafter referred to as the
“Company” (where such expression shall, unless repugnant to the context thereof,
be deemed to include its respective legal heirs, representatives, administrators,
permitted successors and assigns) hereby set out the disclaimer for use of the
Platform Website and domain www.treatsmart.in (hereinafter referred to as
the “Platform”)
Prices shown on the Platform with regard to the delivery of any services will be
tentative, we will not be responsible for any delay. Any changes in the subscription
fee is subject to changes in factors including but not limited to market conditions,
demands for service, etc.
The Company will not be responsible for any damage suffered by users from use
of the services on this site. This includes but is not limited to, loss of revenue/data
resulting from delays, non-deliveries, missed deliveries, or service interruptions as
may occur because of any act/omission of parties. This disclaimer of liability also
applies to any damages or injury caused by any failure of performance, error,
omission, interruption, deletion, defect, delay in operation or transmission,
computer virus, communication line failure, theft or destruction or unauthorized
access to, alteration of, or use of record, whether for breach of contract, tortuous
behaviour, negligence, or under any other cause of action.
The Company also makes it clear that it shall not be held liable for any
damage/hurt/inconvenience caused to the User through the course of the provision
of Services or as a result of the Users’ actions. The Company makes no
representations or warranties as to the conduct of the Users. The Company takes no
responsibility for any content that Users send or receive from other Users or Third-
Party posts through the Website or Platform. Any material downloaded or
otherwise obtained through use of the service is accessed at the Users own
discretion and risk.
The Services on the Platform are intended to be subject to availability, without any
promises or guarantees on the same by the Company, and while certain
information available on the Platform is the property of the Company and the
Company endeavours to keep the said information updated and accurate, the
Company shall not make any representations or warranties of any kind, express or

implied, about the completeness, accuracy, reliability, suitability or availability
with respect to the Platform or the information, Services, or related graphics
contained on the Platform for any purpose. Any reliance you place on such
information is therefore strictly at your own risk.
The Company bears no responsibility whatsoever for any consequences arising
from the use of the said Services by Users. The use of the Services in the Platform
is the sole responsibility of the User (or legally authorised person on behalf of the
User), and in case of any negligence on the part of the User in acting on the same
shall not be construed as imposing any liability, direct or indirect, on the Company
/Platform. The Company reserves the right to make additions, deletions, or
modifications to the contents on the Service at any time without prior notice.
The Company hereby states that the medical opinion shared with the Users is based
on the doctors suggestion and the Platform only acts as a co-ordinator. The
Platform or the Company bear no responsibility towards the treatment plan. It is
further stated that the Platform only facilitates in seeking appointment of doctors
and will not be guaranteeing Appointment time of the doctors which can vary
based on their schedule and the company or the Platform will not be responsible
for the same. It is further stated that the Company shall not be responsible in any
manner for any injury, ailment, death or any other medico legal issues arising as a
consequence of availing the services, the doctors and the hospital providing the
treatment will be solely responsible for the same.
The Company hereby states that with regard to customised Treatment packages,
anything which is not mentioned in the inclusion will be charged extra at the
facility and the Company will not have any responsibility. Any service related
complaint at the facility where the treatment is being sought will not be the
Company’s responsibility. The company will not bear any responsibility for
medical negligence at the hospital and the liablity for the same shall lie entirely
upon the treating doctor
and hospital. It is hereby stated that Company only operates a platform to
aggregate and sell the packages, hence Users are adviced to personally do all the
research with regard to the doctor and facility and the Company shall not bear any
responsibility towards the same.
The information given on the Platform is for general guidance on matters of
interest only. Even if the Company takes every precaution to insure that the content
of the Service is both current and accurate, there is no warranty with regard to the
content on the Platform being error free or completely authentic. In addition to this
considering the changing nature of laws, rules and regulations, there may be
delays, omissions or inaccuracies in the content displayed on the Platform or the
information disseminated from the Platform.

The Company shall disclaim all responsibility and owns no liability to Users for
any outcome (incidental, direct, indirect or otherwise) from the use of the Services.
In no event will the Company be liable for any loss or damage including without
limitation, indirect or consequential loss or damage, or any loss or damage
whatsoever arising from loss of data or profits arising out of, or in connection with,
the use of the Platform. In case of any discrepancy or in delivery of the services or
the availing of the services, the refunds shall be issued in strict compliance with the
“Refund and Cancellation Policy” set out on the Platform.
Through this Platform, you are able to access the link of other Platforms which are
not under the control of the Company. We have no control over the nature, content
and availability of those sites. The inclusion of any links does not necessarily
imply a recommendation or endorse the views expressed within them. Every effort
is made to keep the Platform up and running smoothly. However, the Company
takes no responsibility for, and will not be liable for, the Platform being
temporarily unavailable due to technical issues beyond our control.<span><b>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE
WILL HAVE NO LIABILITY RELATED TO USER CONTENT ARISING
UNDER INTELLECTUAL PROPERTY RIGHTS, LIBEL, PRIVACY,
PUBLICITY, OBSCENITY OR OTHER LAWS. WEBSITE ALSO DISCLAIMS
ALL LIABILITY WITH RESPECT TO THE MISUSE, LOSS, MODIFICATION
OR UNAVAILABILITY OF ANY USER CONTENT.
THE USER UNDERSTANDS AND AGREES THAT ANY MATERIAL OR
DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
PLATFORM IS DONE ENTIRELY AT HIS/HER OWN DISCRETION AND
RISK AND HE/SHE WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE
TO HIS/HER COMPUTER SYSTEMS OR LOSS OF DATA THAT RESULTS
FROM THE DOWNLOAD OF SUCH MATERIAL OR DATA. WE ARE NOT
RESPONSIBLE FOR ANY TYPOGRAPHICAL ERROR LEADING TO AN
INVALID COUPON. PLATFORM ACCEPTS NO LIABILITY FOR ANY
ERRORS OR OMISSIONS, WITH RESPECT TO ANY INFORMATION
PROVIDED TO YOU WHETHER ON BEHALF OF ITSELF OR THIRD
PARTIES.
THE ADVERTISEMENT AVAILABLE ON E-MAIL OR WEBSITE WITH
RESPECT TO THE THIRD-PARTY WEBSITE OR THE PRODUCTS AND
SERVICES ARE FOR INFORMATION PURPOSE ONLY.</b></span></p>
</div>

</div>

<Footer />
    </div>
  )
}

export default disclaimerPolicy