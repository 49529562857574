import React, { useState, useEffect } from "react";
import axios from "axios";
import PatientArea from "../../PatientLandingPage/PatientLandingPageArea/PatientLandingPageArea";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import "../../DoctorProfile/DoctorProfileArea/Doctor.css";
import "./PatientProfileArea.css";

const PatientProfileArea = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [website, setWebsite] = useState("");
  const [file, setFile] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  const patientId = localStorage.getItem("patient_id");

  // Fetch patient data when the component mounts
  useEffect(() => {
    fetchPatientData();
  }, []);

  // Function to fetch patient data from the server
  const fetchPatientData = async () => {
    try {
      const response = await axios.get(
        `https://api.treatsmart.in/api/patients/${patientId}`
      );

      console.log("username coming or not--->>", response);

      // Extract patient data from the response
      const {
        name,
        email,
        phone,
        firstname,
        lastname,
        password,
        address,
        city,
        state,
        country,
        zip,
        speciality,
        website,
        profile_image_url,
      } = response.data;

      // Set state variables with fetched data
      setName(name);
      setEmail(email);
      setFirstName(firstname);
      setLastName(lastname);
      setPassword(password);
      setPhone(phone);
      setAddress(address);
      setCity(city);
      setState(state);
      setCountry(country);
      setZip(zip);
      setSpeciality(speciality);
      setWebsite(website);
      setFile(profile_image_url);
      setImagePreview(profile_image_url);
    } catch (error) {
      console.error("Error fetching patient profile:", error);
    }
  };

  // Function to handle form submission for updating patient profile
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("username", firstName);
      formData.append("firstname", firstName);
      formData.append("lastname", lastName);
      formData.append("password", password);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("country", country);
      formData.append("zip", zip);
      formData.append("speciality", speciality);
      formData.append("website", website);
      if (file) {
        formData.append("photo", file);
      }

      // Send PUT request to update patient profile
      await axios.put(
        `https://api.treatsmart.in/api/patients/${patientId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Fetch updated patient data and show success message
      fetchPatientData();
      Swal.fire({
        title: "success!",
        text: "Profile Updated Scessfully..",
        icon: "success",
        confirmButtonColor: "8fb569",
        confirmButtonText: "OK",
      });

      setUpdateSuccess(true);
    } catch (error) {
      console.error("Error updating patient profile:", error);
    } finally {
      setFile(null);
    }
  };

  // Function to handle file input change and display image preview
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    // Display image preview
    const reader = new FileReader();
    reader.onload = (event) => {
      setImagePreview(event.target.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  return (
    <>
      <div className="Patient-profile-container">
        {/* Sidebar for patient area */}
        <div class="patient-sidebar-container">
          <PatientArea />
        </div>
        {/* Main content area */}
        <div className="patient-details-container">
          <div className="basic-login">
            <h5> Welcome {firstName}</h5>

            <form>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="name">
                    <h6 className="theme-color">
                      First Name<span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Name..."
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="name">
                    <h6 className="theme-color">
                      Last Name<span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    type="text"
                    placeholder="Enter Name..."
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="email-id">
                    <h6 className="theme-color">
                      Email Address <span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    id="email-id"
                    type="email"
                    placeholder="Enter Email address..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="password">
                    <h6 className="theme-color">
                      Password<span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    type="password"
                    placeholder="Enter Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="phone">
                    <h6 className="theme-color">
                      Contact umber <span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    id="phone"
                    type="tel"
                    placeholder="Enter Mobile number..."
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="address">
                    <h6 className="theme-color">
                      Address <span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    id="address"
                    type="text"
                    placeholder="Enter Address..."
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="city">
                    <h6 className="theme-color">
                      City <span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    id="city"
                    type="text"
                    value={city}
                    placeholder="Enter City..."
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="state">
                    <h6 className="theme-color">
                      State <span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    id="state"
                    type="text"
                    value={state}
                    placeholder="Enter State..."
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label htmlFor="country">
                    <h6 className="theme-color">
                      Country <span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    id="country"
                    type="text"
                    value={country}
                    placeholder="Enter country..."
                    onChange={(e) => setCountry(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="zip">
                    <h6 className="theme-color">
                      Post Code <span>*</span>
                    </h6>
                  </label>
                  <input
                    required
                    id="zip"
                    type="text"
                    value={zip}
                    placeholder="Enter Zip Code..."
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="checkout-form-list">
                    <label>
                      <h6 className="theme-color">
                        Upload Image <span className="required">*</span>
                      </h6>
                    </label>
                    <input
                      type="file"
                      name="photo"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>

              {/* Image preview area */}
              {imagePreview && (
                <div className="row">
                  <div className="image-preview-container">
                    <img src={imagePreview} alt="patient-image" />
                  </div>
                </div>
              )}

              <div className="col-md-12 text-center form-group">
                <button
                  type="submit"
                  className="primary_btn btn-icon-green bg-primary w-50"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* display success message */}
      {updateSuccess && (
        <div style={{ color: "green", textAlign: "center" }}>
          Patient profile updated successfully!
        </div>
      )}
    </>
  );
};

export default PatientProfileArea;
