import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import ProcedureOptions from "../../../../components/ProcedureOptions/ProcedureOptions";
import ProcedureAccomodation from "../../../../components/ProcedureAccomodation/ProcedureAccomodation";
import "./ProcedureDetails.css";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const ShopDetailsBanner = ({ data }) => {
  const [optionPrice, setOptionPrice] = useState("");
  const [selectedAccomodationPrice, setSelectedAccomodationPrice] =
    useState("");

  let location = useLocation();
  console.log("after login data ", location.state);

  console.log("comming data and procedure name", data);

  let dataAfterLogin = location.state.procedureData;

  const calculateTotalPrice = () => {
    let totalPrice = parseFloat(price);
    if (optionPrice) totalPrice += parseFloat(optionPrice);
    if (selectedAccomodationPrice)
      totalPrice += parseFloat(selectedAccomodationPrice);
    return totalPrice / 10;
  };
  let {
    id,
    image,
    speciality,
    pname,
    price,
    discount,
    provider_name,
    provider_id,
    description,
  } = dataAfterLogin ? dataAfterLogin : data;
  console.log("==>", data);
  //const statuss = "complete";
  //const status = JSON.stringify(statuss);
  // const status = statuss
  const navigate = useNavigate();
  localStorage.setItem("procedure_id", id);
  const patient_id = localStorage.getItem("patient_id");
  console.log("========>>>", patient_id);
  const Navigate = useNavigate();
  const handleRedirect = () => {
    Navigate("/login", {
      state: {
        data,
      },
    });
  };

  const alertMessage = () => {
    if (!dataAfterLogin || !data) {
      console.log("message , no procedures found");
    }
  };

  useEffect(() => {
    alertMessage();
  }, []);

  async function onPay() {
    const totalPrice = calculateTotalPrice();

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    try {
      const response = await fetch(
        "https://api.treatsmart.in/procedurebookingpayment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            amount: totalPrice * 1000 * 100 * 0.1,
            patient_id: localStorage.getItem("patient_id"),
            procedure_id: id,
            provider_id: provider_id,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Payment initialization failed");
      }

      console.log(data);

      const options = {
        key: "rzp_live_mlwSztC46KoZqd",
        amount: totalPrice * 1000 * 100 * 0.1,
        currency: "INR",
        order_id: data.order.id,
        handler: async function (response) {
          console.log("Payment response:", response);

          // Debugging: Log the entire response object
          alert(JSON.stringify(response));

          // Extract necessary fields from the response
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
            response;

          if (
            !razorpay_payment_id ||
            !razorpay_order_id ||
            !razorpay_signature
          ) {
            console.error(
              "Missing required fields in payment response:",
              response
            );
            alert("Payment verification failed due to missing fields.");
            return;
          }

          // Call the verification API
          try {
            const verificationResponse = await fetch(
              "https://api.treatsmart.in/verifypayment",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  razorpay_payment_id,
                  razorpay_order_id,
                  razorpay_signature,
                  patient_id: localStorage.getItem("patient_id"),
                  procedure_id: id,
                  provider_id: provider_id,
                }),
              }
            );

            const verificationData = await verificationResponse.json();

            if (verificationData.success) {
              alert("Payment successful");
              onBookProcedure("complete");
              navigate("/bookhistory");
              window.location.reload();
            } else {
              alert("Payment verification failed");
            }
          } catch (error) {
            console.error("Error during payment verification:", error);
            alert("Payment verification failed");
          }
        },
        prefill: {
          name: "Your Name",
          email: "your.email@example.com",
          contact: "9999999999",
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: function () {
            console.log("Payment popup closed");
            navigate("/"); // Replace with your target page
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
      alert("Payment initiation failed. Please try again.");
    }
  }

  const onBookProcedure = (status) => {
    const totalPrice = calculateTotalPrice();

    // Make an API request to save the data to the backend
    axios
      .post("https://api.treatsmart.in/api/patients/booking", {
        image,
        speciality,
        pname,
        price: totalPrice,
        discount,
        patient_id,
        procedure_id: id,
        provider_id: provider_id,
        status,
        //status : status
      })

      .then((response) => {
        // Handle the successful response
        console.log("Booking successful:", response.data);
        Navigate("/bookhistory", response);
        // Navigate("/bookhistory");

        // Optionally, you can navigate to a success page or display a success message
      })
      .catch((error) => {
        // Handle the error
        console.error("Error booking procedure:", error);
        // Optionally, you can display an error message to the user
      });
  };

  return (
    <>
      <section className="shop-banner-area pt-3 pb-3">
        <div className="container">
          <div className="precedure-details-container">
            <div className="precedure-image p-1">
              <img src={image} alt="" />
            </div>

            <div className="product-details p-1">
              <div className="product-details-title">
                <p>{speciality}</p>
                <h4>{pname}</h4>
                <div className="price details-price pb-1 mb-1">
                  <span>{price}</span>
                  <span className="old-price">₹ {discount}</span>
                </div>
              </div>

              {/* // procedure options & accomodation code */}
              <div className="faq-right-box">
                <div id="accordion" className="mt-2">
                  <ProcedureOptions
                    number="One"
                    condition={true}
                    secondClassAdd="show"
                    procedureId={data.id}
                    setOptionPrice={setOptionPrice}
                  />
                  <ProcedureAccomodation
                    number="Two"
                    fistClassAdd="collapsed"
                    procedureId={data.id}
                    setSelectedAccomodationPrice={setSelectedAccomodationPrice}
                  />
                </div>
              </div>
              <div>
                <h5> Booking Amount = ₹{calculateTotalPrice()}</h5>
                <h6>
                  Pay 10% of procedure amount to book the selected procedure.
                  Balance to be paid directly to the hospital/provider after the
                  procedure is completed.
                </h6>
              </div>
              <br />
              {/* book procedure button */}
              <div className="product-details-action">
                <form action="#">
                  {patient_id !== null && (
                    <li style={{ display: "flex" }}>
                      <Link
                        to="/shopDetails"
                        className="primary_btn btn-icon btn-icon-green ms-0"
                        style={{ marginRight: "10px", padding: "11px 37px" }}
                        onClick={onPay}
                      >
                        Pay Now
                      </Link>
                      <Link
                        to="/bookhistory"
                        className="primary_btn btn-icon btn-icon-green ms-0"
                        style={{ marginRight: "10px", padding: "11px 37px" }}
                        onClick={() => onBookProcedure("pending")}
                      >
                        Pay Later
                      </Link>
                    </li>
                  )}
                  {patient_id === null && (
                    <li>
                      <button
                        className="primary_btn btn-icon ml-0"
                        onClick={handleRedirect}
                      >
                        <span>+</span> Book Procedure
                      </button>
                    </li>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopDetailsBanner;
