import React from "react";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import Footer from "../../components/Shared/Footer";
import MonthlyTransactionArea from "./MonthlyTransactionArea/MonthlyTransactionArea";

function MonthlyTransaction() {
  return (
    <>
      <HomeThreeNavBar />
      <MonthlyTransactionArea />
      <Footer />
    </>
  );
}

export default MonthlyTransaction;
