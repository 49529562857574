import React, { useState, useEffect } from "react";
import ServicesTwoSingleItem from "../../../components/ServicesTwoSingleItem/ServicesTwoSingleItem";
import "./BookingHistoryArea.css";

function BookingHistoryArea() {
  const [bookingData, setBookingData] = useState([]);
  const patientId = localStorage.getItem("patient_id");
  console.log("p1===>", bookingData);
  useEffect(() => {
    // Fetch booking data here
    const fetchBookingData = async () => {
      try {
        const response = await fetch(
          `https://api.treatsmart.in/patient/${patientId}`
        );
        const data = await response.json();
        console.log("booking data >>>>", data[0].status);
        setBookingData(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBookingData();
  }, []);
  console.log("dsasd", bookingData);
  return (
    <div className="booking-history-area">
      {bookingData.length > 0 ? (
        <>
          {bookingData.map((booking) => (
            <ServicesTwoSingleItem bookingData={booking} />
          ))}
        </>
      ) : (
        <p>No booking history found.</p>
      )}
    </div>
  );
}

export default BookingHistoryArea;
