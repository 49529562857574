import React from "react";
import HomeThreeNavBar from "../HomeThree/HomeThreeNavBar/HomeThreeNavBar";
import Footer from "../../components/Shared/Footer";
import AdminDashboardArea from "./AdminDashboardArea/AdminDashboardArea";

function AdminDashboard() {
  return (
    <>
      <HomeThreeNavBar />
      <AdminDashboardArea />
      <Footer />
    </>
  );
}

export default AdminDashboard;
