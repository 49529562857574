import React, { useState, useEffect } from "react";
import "./MonthlyTransactionArea.css";
import AdminSidebar from "../../AdminProfile/AdminSidebar";
import TransactionDataTable from "../../../../src/components/TransactionTable/TransactionDataTable";
import useTransactionData from "../../../hooks/useTransactionData";

function MonthlyTransactionArea() {
  const { data, columns, loading, error } = useTransactionData(
    "https://api.treatsmart.in/transactionCurrentMonth"
  );

  return (
    <div className="monthly-transaction-container">
      <div className="admin-sidebar-area">
        <AdminSidebar />
      </div>
      <div className="monthly-transaction-main-container">
        <h4>Monthly Transaction Details</h4>
        <div className="monthly-transaction-table-container">
          <TransactionDataTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
}

export default MonthlyTransactionArea;
