import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios"; // Import Axios for making HTTP requests
import { Card } from "react-bootstrap";

const SingleShop = ({
  id,
  image,
  speciality,
  pname,
  price,
  discount,
  description,
  provider_name,
  provider_id,
  address,
  city,
  country,
  phone,
  doctor_name,
  doctor_id,
  doctor_image,
}) => {
  const Navigate = useNavigate();
  const { state } = useLocation();
  const patient_id = localStorage.getItem("patient_id");
  console.log("========>>>", id);
  const { result } = state;

  console.log("want procedure id >>>>>", result);

  const onMoreDetails = () => {
    console.log("description==>", description);
    Navigate("/shopDetails", {
      state: {
        id,
        image,
        speciality,
        pname,
        price,
        discount,
        description,
        provider_name,
        provider_id,
        address,
        city,
        country,
        phone,
        doctor_name,
        doctor_id,
        doctor_image,
      },
    });
  };
  const handleRedirect = (e) => {
    console.log("seleceted values", e.target.value);
    let data;

    Navigate("/login", {
      state: {
        data: {
          result: true,
          id,
          image,
          speciality,
          pname,
          price,
          discount,
          description,
          provider_name,
          provider_id,
          address,
          city,
          country,
          phone,
          doctor_name,
          doctor_id,
          doctor_image,
        },
      },
    });
  };

  return (
    <>
      <div className="col-lg-4 col-md-6 mb-3">
        <Card>
          <Card.Header>
            <div className="product__img">
              <img
                src={image}
                alt=""
                style={{
                  height: "11rem",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </Card.Header>
          <Card.Body className="p-2 text-center">
            <h5>
              <span className="pro-cat">
                <a>{speciality}</a>
              </span>
            </h5>
            <h4 className="pro-title">{pname}</h4>
            <h5>
              <div className="price">
                <span>{price}</span>
                <span className="old-price">₹ {discount}</span>
              </div>
            </h5>
            <h6 className="pro-title">{provider_name}</h6>
            <h6 className="pro-title">
              <img
                src={doctor_image}
                alt={doctor_name}
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              />
              {doctor_name}
            </h6>
            <div className="product-action-text-center">
              {patient_id !== null && (
                <li className="mb-2">
                  <button
                    className="primary_btn btn-icon"
                    onClick={onMoreDetails}
                  >
                    <i class="fa fa-medkit" aria-hidden="true"></i> Book
                    Procedure
                  </button>
                </li>
              )}
              {patient_id === null && (
                <li>
                  <button
                    className="primary_btn btn-icon"
                    onClick={handleRedirect}
                  >
                    <i class="fa fa-medkit"></i> Book Procedure
                  </button>
                </li>
              )}

              <button
                className="action-btn"
                onClick={onMoreDetails}
                style={{ backgroundColor: "#e7e7e7", color: "black" }}
              >
                <i className="fas fa-expand text-dark"></i> More Details
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default SingleShop;
