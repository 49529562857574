import React, { useState, useEffect } from "react";
import "./DailyTransactionArea.css";
import AdminSidebar from "../../AdminProfile/AdminSidebar";
import useTransactionData from "../../../hooks/useTransactionData";
import TransactionDataTable from "../../../components/TransactionTable/TransactionDataTable";

function DailyTransactionArea() {
  const { data, columns, loading, error } = useTransactionData(
    "https://api.treatsmart.in/transactionToday"
  );

  return (
    <div className="daily-transaction-container">
      <div className="admin-sidebar-area">
        <AdminSidebar />
      </div>
      <div className="daily-transaction-main-container">
        <h4>Daily Transaction Details</h4>
        <div className="daily-transaction-table-container">
          <TransactionDataTable columns={columns} data={data} />
        </div>
      </div>
    </div>
  );
}

export default DailyTransactionArea;
