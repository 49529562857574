import React from 'react'
import BookProcedureArea from './BookProcedureArea/BookProcedureArea'
import HomeThreeNavBar from '../HomeThree/HomeThreeNavBar/HomeThreeNavBar'
import Footer from '../../components/Shared/Footer'

function BookProcedure() {
  return (
    <div>
< HomeThreeNavBar />
<BookProcedureArea />
<Footer />

    </div>
  )
}

export default BookProcedure