import React from "react";
import Footer from "../../components/Shared/Footer";
import HomeThreeAboutArea from "./HomeThreeAboutArea/HomeThreeAboutArea";
import HomeThreeFactArea from "./HomeThreeFactArea/HomeThreeFactArea";
import HomeThreeHeroSection from "./HomeThreeHeroSection/HomeThreeHeroSection";
import HomeThreeNavBar from "./HomeThreeNavBar/HomeThreeNavBar";

const HomeThree = () => {
  const patientId = localStorage.getItem("patient_id");
  return (
    <>
      <HomeThreeNavBar />
      <HomeThreeHeroSection />
      <HomeThreeAboutArea />
      <HomeThreeFactArea />
      <Footer />
    </>
  );
};

export default HomeThree;
