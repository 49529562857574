import React, { useState } from "react";
import ConsultationHistory from "../../ConsultationHistory/ConsultationHistory";
import BookingHistoryArea from "./BookingHistoryArea";
import PatientArea from "../../PatientLandingPage/PatientLandingPageArea/PatientLandingPageArea";
import "./BookingHistory.css";

function BookingHistory() {
  const [showBookingHistoryArea, setShowBookingHistoryArea] = useState(false);
  const [showConsultationHistory, setShowConsultationHistory] = useState(true);

  const handleBookingHistoryArea = () => {
    setShowBookingHistoryArea(true);
    setShowConsultationHistory(false);
  };

  const handleConsultationHistory = () => {
    setShowConsultationHistory(true);
    setShowBookingHistoryArea(false);
  };

  return (
    <div className="booking-history-container">
      <div className="patient-sidebar-container">
        <PatientArea />
      </div>
      <div className="history-details-container">
        <div className="containersign">
          <button
            className={showConsultationHistory ? "tabs active-tabs" : "tabs"}
            onClick={handleConsultationHistory}
          >
            Consultation History
          </button>
          <button
            className={showBookingHistoryArea ? "tabs active-tabs" : "tabs"}
            onClick={handleBookingHistoryArea}
          >
            Booking History
          </button>
        </div>

        {showBookingHistoryArea && <BookingHistoryArea />}
        {showConsultationHistory && <ConsultationHistory />}
      </div>
    </div>
  );
}

export default BookingHistory;
