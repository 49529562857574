import React, { useState, useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { Table, Button } from "react-bootstrap";
import "./TransactionDataTable.css";

// Function to format timestamp
const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return date.toLocaleDateString("en-GB", options);
};

const TransactionDataTable = ({ data }) => {
  const [columns, setColumns] = useState([]);
  const [expandedRowColumns, setExpandedRowColumns] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    const updateScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", updateScreenSize);
    updateScreenSize(); // Initial check

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    const mainColumns = [
      { Header: "ID", accessor: "id" },
      { Header: "Amount", accessor: "amount" },
      { Header: "Payment Method", accessor: "payment_method" },
      {
        Header: "Date",
        accessor: "timestamp",
        Cell: ({ value }) => formatTimestamp(value),
      },
      { Header: "Status", accessor: "status" },
    ];

    const extraColumnsLarge = [
      {
        Header: "Patient Name",
        accessor: "name",
        Cell: ({ row }) => `${row.original.firstname} ${row.original.lastname}`,
      },
      { Header: "Procedure Name", accessor: "pname" },
      { Header: "Provider Name", accessor: "provider_name" },
    ];

    const expandedRowColumnsBase = [
      { Header: "Order ID", accessor: "order_id" },
      { Header: "Payment ID", accessor: "payment_id" },
      { Header: "Provider ID", accessor: "provider_idd" },
      { Header: "Signature ID", accessor: "signature_id" },
      { Header: "Procedure ID", accessor: "procedure_id" },
      { Header: "Patient ID", accessor: "patient_id" },
      { Header: "Receipt", accessor: "receipt" },
    ];

    if (isSmallScreen) {
      setColumns(mainColumns);
      setExpandedRowColumns([...expandedRowColumnsBase, ...extraColumnsLarge]);
    } else {
      setColumns([...mainColumns, ...extraColumnsLarge]);
      setExpandedRowColumns(expandedRowColumnsBase);
    }
  }, [isSmallScreen]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const toggleRowExpansion = (rowIndex) => {
    setExpandedRows((prev) =>
      prev.includes(rowIndex)
        ? prev.filter((index) => index !== rowIndex)
        : [...prev, rowIndex]
    );
  };

  return (
    <>
      <Table {...getTableProps()} responsive className="custom-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              className="custom-header"
            >
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            const isRowExpanded = expandedRows.includes(rowIndex);
            return (
              <React.Fragment key={rowIndex}>
                <tr
                  {...row.getRowProps()}
                  className="custom-row"
                  onClick={() => toggleRowExpansion(rowIndex)}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
                {isRowExpanded && (
                  <tr className="expanded-row">
                    <td colSpan={columns.length}>
                      <div className="expanded-content">
                        {expandedRowColumns.map((col) => (
                          <p key={col.accessor}>
                            <strong>{col.Header}:</strong>{" "}
                            {row.original[col.accessor]}
                          </p>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination">
        <div className="pagination-buttons">
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>{" "}
          <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </Button>{" "}
          <Button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </Button>{" "}
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>{" "}
        </div>
        <div className="pagination-detail">
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default TransactionDataTable;
