import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Register from "../../Register/Register";
import ForgotPassword from "../../ForgotPassword/ForgotPassword";
import "./LoginArea.css";
import { NavItem } from "react-bootstrap";

const LoginArea = ({ data }) => {
  // State hooks for email, password, login status, and error message
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  console.log(data);

  // Store incoming data or set to an empty string if not provided
  const procedureData = data ? data : "";

  // const newProcedureData = {
  //   address : procedureData.address,

  //   city : procedureData.city,

  //   country : procedureData.country,

  //   description : procedureData.description,

  //   discount : procedureData.discount,

  //   doctor_id : procedureData.doctor_id,

  //   doctor_image : procedureData.doctor_image,

  //   doctor_name : procedureData.doctor_name,

  //   id : procedureData.id,

  //   image : procedureData.image,

  //   phone : procedureData.phone,

  //   pname : procedureData.pname,

  //   price : procedureData.price,

  //   provider_id : procedureData.provider_id,

  //   provider_name : procedureData.provider_name,

  //   result : procedureData.result,

  //   speciality : procedureData.speciality,

  // }

  // Function to handle login
  const login = (e) => {
    e.preventDefault();

    Axios.post("https://api.treatsmart.in/login", {
      email: email,
      password: password,
    })
      .then((response) => {
        const {
          message,
          username,
          patient_id,
          name,
          provider_id,
          sessionId,
          role,
        } = response.data;
        console.log("response after login", response.data);
        if (role == "admin") {
          localStorage.setItem("userRole", "admin");

          navigate("/adminProfile");
        }
        // If login is successful
        else {
          if (message) {
            // Store session information in localStorage
            if (sessionId) {
              const sessionKey = Object.keys(sessionId)[0];
              const sessionExpirationTime = new Date(
                sessionId[sessionKey].expirationTime
              );
              localStorage.setItem("sessionKey", sessionKey);
              localStorage.setItem(
                "sessionExpirationTime",
                sessionExpirationTime
              );
            }
            // Store user information in localStorage and navigate accordingly
            if (username) {
              localStorage.setItem("username", username);
              localStorage.setItem("patient_id", patient_id);

              procedureData
                ? navigate("/shopDetails", { state: { procedureData } })
                : navigate("/BookAppointment");
            } else {
              localStorage.setItem("name", name);
              localStorage.setItem("provider_id", provider_id);

              navigate("/DoctorProfileArea");
            }
          } else {
            setLoginStatus("");
            localStorage.setItem("userEmail", response.data.email);
            Swal.fire({
              title: "Error!",
              text: "Check Email and Password",
              icon: "error",
              confirmButtonColor: "8fb569",
              confirmButtonText: "OK",
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: "Error!",
          text: "Check Email and Password",
          icon: "error",
          confirmButtonColor: "8fb569",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <>
      {/* Login section */}
      <section className="login-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 d-flex justify-content-center">
              <div className="basic-login">
                <h5 className="text-center mb-3">Login From Here</h5>
                {errorMessage && (
                  <h1
                    style={{
                      color: "red",
                      fontSize: "15px",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    {errorMessage}
                  </h1>
                )}
                <form>
                  <label htmlFor="name" className="mb-2">
                    <h6 className="theme-color">
                      Email Address <span>*</span>
                    </h6>
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    required
                    id="name"
                    type="text"
                  />

                  <label htmlFor="pass" className="mb-2">
                    <h6 className="theme-color">
                      Password <span>*</span>
                    </h6>
                  </label>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                    id="pass"
                    type="password"
                  />

                  {/* Login actions */}
                  <div className="login-action mb-20 fix">
                    <span className="log-rem f-left">
                      <input id="remember" type="checkbox" />
                      <label htmlFor="remember">Remember me!</label>
                    </span>
                    <span className="forgot-login f-right">
                      <Link to="/ForgotPassword">
                        <button type="button" class="btn btn-link">
                          <i
                            class="fa fa-key text-secondary"
                            aria-hidden="true"
                          ></i>
                          <span>Forgot your password?</span>
                        </button>
                      </Link>{" "}
                    </span>
                  </div>
                  {/* Login button */}
                  <div className="d-flex flex-column align-items-center">
                    <button
                      type="submit"
                      className="primary_btn btn-icon-green w-50"
                      onClick={login}
                    >
                      <i class="fa fa-sign-in" aria-hidden="true"></i>
                      Login Now
                    </button>
                    <h1
                      style={{
                        color: "red",
                        fontSize: "15px",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      {loginStatus}
                    </h1>
                    <div className="or-divide">
                      <span>or</span>
                    </div>
                    <Link to="/register" state={data}>
                      <button className="primary_btn theme-btn w-100">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        Register Now
                      </button>
                    </Link>
                  </div>
                </form>
                <div className="or-divide or-login">
                  <span>or login with</span>
                </div>
                <button className="login_btn">
                  <img src="img/icon/google_icon.svg" alt="" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginArea;
