import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ProcedureAccomodation.css";

const ProcedureAccomodation = ({
  procedureId,
  number,
  condition = false,
  fistClassAdd,
  secondClassAdd,
  setSelectedAccomodationPrice,
}) => {
  console.log(
    "Procedure ID to show accommodation option in procedure is:",
    procedureId
  );

  const [selectedAccomodations, setSelectedAccomodations] = useState([]); // Array for multiple selections
  const [Accomodations, setAccomodation] = useState([]);

  useEffect(() => {
    axios
      .get(`https://api.treatsmart.in/procedure/accomodation/${procedureId}`) // Adjust the URL to match your API endpoint
      .then((response) => {
        setAccomodation(response.data.results);
        console.log("Accommodation data:", response.data.results);
      })
      .catch((error) => {
        console.error("Error fetching options:", error);
      });
  }, [procedureId]);

  const handleAccomodationChange = (event) => {
    const selectedAccomodationName = event.target.value;
    let updatedSelections = [...selectedAccomodations];

    // Add or remove the selected accommodation from the array
    if (event.target.checked) {
      updatedSelections.push(selectedAccomodationName);
    } else {
      updatedSelections = updatedSelections.filter(
        (accommodation) => accommodation !== selectedAccomodationName
      );
    }

    setSelectedAccomodations(updatedSelections);

    // Calculate total price of selected accommodations
    const selectedAccomodationData = Accomodations.filter((accommodation) =>
      updatedSelections.includes(accommodation.accommodation_type)
    );

    const totalSelectedPrice = selectedAccomodationData.reduce(
      (total, accommodation) => total + parseFloat(accommodation.rate || 0),
      0
    );

    setSelectedAccomodationPrice(totalSelectedPrice); // Update the price based on selected accommodations
    console.log("Selected Accommodation Price:", totalSelectedPrice);
  };

  const handleClearSelection = () => {
    setSelectedAccomodations([]);
    setSelectedAccomodationPrice(0);
  };

  return (
    <>
      <div className="card">
        <div className="card-header" id={`heading${number}`}>
          <h5 className="mb-0">
            <a
              href="#"
              className={`btn-link ${fistClassAdd && fistClassAdd}`}
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${number}`}
              aria-controls={`collapse${number}`}
              aria-expanded={condition}
            >
              Procedure Accommodation Options
            </a>
          </h5>
        </div>
        <div
          id={`collapse${number}`}
          className={`collapse ${secondClassAdd && secondClassAdd}`}
          aria-labelledby={`heading${number}`}
          data-parent="#accordion"
        >
          <div className="card-body">
            <form>
              {Accomodations.map((Accomodation) => (
                <label key={Accomodation.accommodation_id} className="radio-label">
                  <div className="radio-left">
                    <input
                      type="checkbox"
                      name="option"
                      value={Accomodation.accommodation_type}
                      checked={selectedAccomodations.includes(Accomodation.accommodation_type)}
                      onChange={handleAccomodationChange}
                    />
                    <p>{Accomodation.accommodation_type}</p>
                  </div>
                  <div className="radio-right">
                    <p>₹ {Accomodation.rate}</p>
                  </div>
                </label>
              ))}
            </form>
            {selectedAccomodations.length > 0 && (
              <button className="btn btn-danger" onClick={handleClearSelection}>
                Clear Selection
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcedureAccomodation;
