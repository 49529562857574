import { useNavigate } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AddNewProcedureArea from "../../AddNewProcedure/AddNewProcedureArea/AddNewProcedureArea";
import UpdateProcedureArea from "../../UpdateProcedure/UpdateProcedureArea/UpdateProcedureArea";
import ManageProcedure from "../ProviderLandingPageArea/ManageProcedure";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import "../../DoctorProfile/DoctorProfileArea/Doctor.css";

function ProviderLandingPageArea() {
  const [showAddNewProcedureArea, setShowAddNewProcedureArea] = useState(false);
  const [showUpdateProcedureArea, setShowUpdateProcedureArea] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [showProcedure, setshowProcedure] = useState(true);
  const [selectedProcedure, setSelectedProcedure] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [name, setUsername] = useState("");
  const [pId, setPId] = useState("");
  const navigate = useNavigate();
  const [procedureOne, setprocedureOne] = useState([]);
  const [selectedProcedurelist, setselectedProcedurelist] = useState("");

  useEffect(() => {
    // Fetch provider name and ID from local storage
    const storedUsername = localStorage.getItem("name");
    if (storedUsername) {
      setUsername(storedUsername);
      console.log("provider name", storedUsername);
    }
    const storedPId = localStorage.getItem("provider_id");
    if (storedPId) {
      setPId(storedPId);
      console.log("provider id", storedPId);
    }

    // Fetch procedures for the provider
    const fetchData = async () => {
      try {
        await fetch(
          `https://api.treatsmart.in/provider/procedure/search/${storedPId}`
        )
          .then((response) => response.json())
          .then((result) => {
            setProcedures(result.procedures); // Update procedures state with fetched data
            console.log("new1==>", setProcedures(result.procedures));
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.error(error);
      }
    };

    fetchData(); // Call fetchData to load procedures
  }, []);

  useEffect(() => {
    // Fetch procedure details based on the selected procedure ID
    const fetchprocedure = async () => {
      try {
        if (selectedProcedurelist) {
          await fetch(
            `https://api.treatsmart.in/provider/procedures/search/${selectedProcedurelist}`
          )
            .then((res) => res.json())
            .then((data) => setprocedureOne(data.procedure)) // Update state with fetched procedure details
            .catch((err) => console.log(err));
        } else {
          setprocedureOne([]); // Reset procedure details if no selection
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchprocedure(); // Call to fetch procedure details
    // Adding a timeout of 2 seconds before executing the fetchProcedure function
    const timeoutId = setTimeout(() => {
      fetchprocedure();
    }, 2000);

    // Clean up the effect and clear the timeout
    return () => clearTimeout(timeoutId);
  }, [selectedProcedurelist]);

  // Handle selection change for procedure dropdown
  const handlesetselectedProcedurelistChange = (e) => {
    const selectedValue = e.target.value; // Get selected procedure ID
    console.log("Selected Procedure:", selectedValue);
    setselectedProcedurelist(selectedValue); // Update selected procedure ID
    setShowResult(true); // Show results section
  };

  // Show update procedure section
  const handleShowUpdateProcedureArea = (procedure) => {
    setSelectedProcedure(procedure); // Set selected procedure for editing
    setShowAddNewProcedureArea(false); // Hide add new procedure section
    setShowUpdateProcedureArea(true); // Show update procedure section
    setshowProcedure(false); // Hide procedure list
  };

  // Show add new procedure section
  const handleshowAddNewProcedureArea = () => {
    setShowAddNewProcedureArea(true); // Show add new procedure section
    setShowUpdateProcedureArea(false); // Hide update procedure section
    setshowProcedure(false); // Hide procedure list
  };

  // Handle procedure deletion
  const handleDelete = async (id) => {
    console.log(id);

    const response = await axios.delete(
      `https://api.treatsmart.in/procedures/delete/${id}`
    );

    if (response.status == 200) {
      alert("Procedure Deleted sucessfully"); // Alert user upon successful deletion
    }
  };

  return (
    <div>
      <div className="col">
        <section className="login-area">
          <div className="container-fluid">
            <div className="row flex-nowrap">
              <div
                class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 "
                style={{ backgroundColor: "#223645" }}
              >
                {" "}
                <br></br>
                <ManageProcedure />
              </div>
              <div className="col-lg-9 d-flex justify-content-center">
                {/* Conditionally render add/update procedure components */}
                {showAddNewProcedureArea && <AddNewProcedureArea />}
                {showUpdateProcedureArea && (
                  <UpdateProcedureArea procedure={selectedProcedure} />
                )}
                {showProcedure && (
                  <div className="basic-login">
                    <h5 className="text-center mb-3">Manage Procedures</h5>

                    <form className="service-contact-form" action="">
                      <form className="service-contact-form" action="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="contact-input contact-icon contact-hourglass">
                              <h6>
                                <select
                                  onChange={
                                    handlesetselectedProcedurelistChange // Handle change in procedure selection
                                  }
                                  className="form-select select_style form-group"
                                  aria-label="Default select example"
                                >
                                  <option
                                    defaultValue="Select Procedure"
                                    value=""
                                  >
                                    Select Procedure
                                  </option>
                                  {procedures.map((procedure) => (
                                    <option
                                      key={procedure.id}
                                      value={procedure.id}
                                    >
                                      {procedure.pname}
                                    </option>
                                  ))}
                                </select>
                              </h6>
                            </div>
                          </div>

                          <div className="col-md-12 text-center mt-40 form-group">
                            <button
                              type="submit"
                              className="primary_btn btn-icon-green w-50"
                              onClick={handleshowAddNewProcedureArea} // Show add new procedure area on button click
                            >
                              Add Procedure
                            </button>
                          </div>

                          {showResult && (
                            <div className="col-lg-12 mt-4">
                              <table className="table table-striped table-hover select_style">
                                <thead>
                                  <tr className="col-xs-12">
                                    <th className="col-xs-4"></th>
                                    <th className="col-xs-5">Procedure Name</th>
                                    <th className="col-xs-3">Action</th>
                                  </tr>
                                </thead>

                                {/* Display procedure image */}
                                <tbody style={{ verticalAlign: "middle" }}>
                                  {procedureOne.map((procedure, index) => (
                                    <tr
                                      key={procedure.id}
                                      className="col-xs-12"
                                    >
                                      <td className="col-xs-4">
                                        <img
                                          src={procedure.procedure_image} // Replace 'imageURL' with the actual property name that holds the image URL in your 'doctor' object
                                          alt="Doctor"
                                          style={{
                                            maxWidth: "4rem",
                                            maxHeight: "3rem",
                                            objectFit: "contain",
                                          }} // Ensure the image fits within the table cell
                                        />
                                      </td>
                                      <td className="col-xs-5">
                                        {procedure.pname}
                                      </td>

                                      <td className="col-xs-3">
                                        <div className="d-flex flex-sm-row">
                                          <FaRegEdit
                                            className="editicon"
                                            onClick={() => {
                                              // Show update area on edit icon click
                                              handleShowUpdateProcedureArea(
                                                procedure
                                              );
                                            }}
                                          />

                                          <FaTrash
                                            className="deleteicon"
                                            onClick={
                                              () => handleDelete(procedure.id) // Handle delete on trash icon click
                                            }
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </form>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default ProviderLandingPageArea;
